// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
// import { APP_ROUTES } from "../../PageRouting/AppRoutes";
// import AgoraUIKit from "agora-react-uikit";

// function VideoCall() {
//   const userData = useSelector((state) => state.authReducer.patientData);

//   const location = useLocation();
//   const history = useHistory();
//   const [rtcPropsId, setRtcPropsId] = useState(null); 
//   const [cameraEnabled, setCameraEnabled] = useState(true);
//   const [videoCall, setVideoCall] = useState(false); 
//   const { state } = location;
//   const queryParams = new URLSearchParams(location.search);
//   const joinCode = queryParams.get('joinCode') || localStorage.getItem('joinCode');
//   const consultationType = queryParams.get('consultationType') || localStorage.getItem('consultationType');

//   useEffect(() => {
//     if (!userData.code) {
//       history.push({
//         pathname: APP_ROUTES.HOME,
       
//       });
//     }
//   }, [userData, history]);



//   // const getLink = () => {
//   //   const header = {
//   //     secretKey: process.env.REACT_APP_MAGGIEPLUS_SECRET_KEY,
//   //   };
//   //   const data = {
//   //     clientCode: "CCM@@202!",
//   //     userType: "HOST",
//   //     meetingKey: state.id,
//   //     memberName: userData.firstName,
//   //     memberEmail: userData.email,
//   //     memberMobile: userData.mobile,
//   //   };
//   //   axios
//   //     .post(`${process.env.REACT_APP_MAGGIEPLUS}`, data, { headers: header })
//   //     .then((result) => {
//   //       if (state?.consultationsType == "A") {
//   //         setUrl(result.data.response.url + "/1");
//   //       } else {
//   //         setUrl(result.data.response.url);
//   //       }

//   //       setTimeout(() => {
//   //         document.getElementById("videoframe").style.display = "none";
//   //         document.getElementById("videoload").style.display = "block";
//   //       }, 100);
//   //       setTimeout(() => {
//   //         document.getElementById("videoframe").style.display = "block";
//   //         document.getElementById("videoload").style.display = "none";
//   //       }, 6000);
//   //     })
//   //     .catch((err) => {
//   //       console.log("Err", err);
//   //     });
//   // };
//   useEffect(() => {
//     if (!state?.id) {
//       console.log("Waiting for state.id...");
//       return; // Exit if state?.id is not yet available
//     }
//     console.log(state?.id);
//     setupAgora(); 
//   }, [state?.id]);

//   const setupAgora = async () => {
//     try {
//       const channelId = state?.id ? String(state.id) : "defaultChannel";
//       const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");
//       const response = await axios.get(
//         `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
//       );
//       const token = response.data?.data?.token;

//       if (!token) {
//         throw new Error("Token generation failed or token is missing");
//       }

//       const rtcProps = {
//         appId: "5da64dc39db9467485eeaac6d515f210",
//         channel: sanitizedChannelId || "TempChannel",
//         token: token,
//         uid: response.data?.data?.uid,
//         enableAudio: true,
//         enableVideo: cameraEnabled, // Toggle camera based on state
//       };
//       setRtcPropsId(rtcProps);
//       setVideoCall(true);
//     } catch (error) {
//       console.error("Error setting up Agora:", error);
//     }
//   };

//   useEffect(() => {
//     if (userData.code && joinCode) { 
//       setupAgora();
//     }
//   }, [userData, joinCode]);

//   if (!rtcPropsId) {
//     console.log(rtcPropsId, "RTCPROPS");
//     return (
//       <p
//         style={{
//           width: "100px",
//           height: "100px",
//           margin: "auto",
//           textAlign: "center",
//         }}
//       >
//         Loading...
//       </p>
//     );
//   }

//   const getPatientInitials = (name) => {
//     const names = name?.split(" ") || [];
//     const initials = names.map((n) => n[0]?.toUpperCase()).join("");
//     return initials || "P"; // Fallback to "P" if no initials are found
//   };

//   const endconsultation = () => {
//     history.push(APP_ROUTES.HOME);
//   };

//   const callbacks = {
//     EndCall: () => setVideoCall(false),
//   };

  

//   return (
//     <>
//       <div className="p-3 md:p-10 flex-col">
//         <div className="flex space-x-4 flex-col">
//           <div className="w-full">
//             {videoCall && rtcPropsId && (
//               <div style={{ display: "flex", width: "100%", height: "80vh" }}>
//                 {cameraEnabled ? (
//                   <AgoraUIKit
//                     rtcProps={rtcPropsId}
//                     callbacks={callbacks}
//                     style={{ width: "100%", height: "100%" }}
//                   />
//                 ) : (
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       width: "100%",
//                       height: "100%",
//                       fontSize: "50px",
//                       backgroundColor: "#ececec",
//                     }}
//                   >
//                     {getPatientInitials(state?.patientName || "Patient")}
//                   </div>
//                 )}
//               </div>
//             )}
//           </div>

//           <div className="self-center mt-8 text-primaryColor">
//             {`To view and download your prescription please go to My Profile-->Click on My Health Records-->Click on My Prescription`}
//           </div>

//           <div className="w-full bottom-0 flex justify-center mt-5">
//             <button
//               onClick={endconsultation}
//               className="bg-secondaryColor  text-sm text-White font-normal rounded-md py-2 px-3 mr-2"
//             >
//               End Consultation
//             </button>
//             <br />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
// export default VideoCall;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";

function VideoCall() {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [url, setUrl] = useState("");
  const location = useLocation();
  const history = useHistory();
  const { state } = location;
  const queryParams = new URLSearchParams(location.search);
  const joinCode = queryParams.get('joinCode') || localStorage.getItem('joinCode');
  const consultationType = queryParams.get('consultationType') || localStorage.getItem('consultationType');

  const getLink = () => {
    const header = {
      secretKey: process.env.REACT_APP_MAGGIEPLUS_SECRET_KEY,
    };
    const data = {
      clientCode: "CCM@@202!",
      userType: "HOST",
      meetingKey: joinCode ? joinCode : state.id,
      memberName: userData.firstName,
      memberEmail: userData.email,
      memberMobile: userData.mobile,
    };
    axios
      .post(`${process.env.REACT_APP_MAGGIEPLUS}`, data, { headers: header })
      .then((result) => {
        if (state?.consultationsType == "A") {
          setUrl(result.data.response.url + "/1");
        } else {
          setUrl(result.data.response.url);
        }

        setTimeout(() => {
          document.getElementById("videoframe").style.display = "none";
          document.getElementById("videoload").style.display = "block";
        }, 100);
        setTimeout(() => {
          document.getElementById("videoframe").style.display = "block";
          document.getElementById("videoload").style.display = "none";
        }, 6000);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  useEffect(() => {
    if (!userData.code && joinCode) {
      history.push({
        pathname: APP_ROUTES.HOME,
       
      });
    }
  }, [userData, joinCode]);

  useEffect(() => {
    if (!state?.id) {
      console.log("Waiting for state.id...");
      return; // Exit if state?.id is not yet available
    }
    getLink();

  }, [state?.id]);

  useEffect(() => {
    if (userData.code && joinCode) { 
      getLink();
    }
  }, [userData, joinCode]);
  

  if (!url) {
    return <p>Loading...</p>;
  }

  const endconsultation = () => {
    history.push(APP_ROUTES.HOME);
  };

  return (
    <>
      <div className="p-3 md:p-10 flex-col">
        <div className="flex space-x-4 flex-col">
          <div className="w-full">
            <div className="relative">
              <div
                id="videoload"
                style={{
                  width: "100px",
                  height: "100px",
                  margin: "auto",
                  textAlign: "center",
                  display: "none",
                }}
              >
                Loading .....
              </div>
              <div id="videoframe">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<iframe class="myframe"  allow="camera; microphone;" sandbox = "allow-scripts allow-same-origin allow-modals allow-top-navigation" style="width: 100%; height:500px" src=${url} frameborder="0" allowfullscreen showControls ></iframe>`,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="self-center mt-8 text-primaryColor">
            {`To view and download your prescription please go to My Profile-->Click on My Health Records-->Click on My Prescription`}
          </div>

          <div className="w-full bottom-0 flex justify-center mt-5">
            <button
              onClick={endconsultation}
              className="bg-secondaryColor  text-sm text-White font-normal rounded-md py-2 px-3 mr-2"
            >
              {url && ` End Consultation`}
            </button>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}
export default VideoCall;

