import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, TimePicker } from "antd";

const PreferedDateAndTime = (props) => {
  const {
    preferDate,
    preferTime,
    changeDate,
    handleChange,
    limitTimeForLab,
    labStartTime,
    labEndTime,
    labType
  } = props;
  const handleDateChange = (date, dateString) => {
    changeDate(dateString)
  };

  const [preferredLabTime, setPreferredLabTime] = useState([
    { label: "06 AM - 07 AM", value: "06 AM - 07 AM" },
  { label: "07 AM - 08 AM", value: "07 AM - 08 AM" },
  { label: "08 AM - 09 AM", value: "08 AM - 09 AM" },
  { label: "09 AM - 10 AM", value: "09 AM - 10 AM" },
  { label: "10 AM - 11 AM", value: "10 AM - 11 AM" },
  { label: "11 AM - 12 PM", value: "11 AM - 12 PM" },
  { label: "12 PM - 01 PM", value: "12 PM - 01 PM" },
  { label: "01 PM - 02 PM", value: "01 PM - 02 PM" },
  { label: "02 PM - 03 PM", value: "02 PM - 03 PM" },
  { label: "03 PM - 04 PM", value: "03 PM - 04 PM" },
  { label: "04 PM - 05 PM", value: "04 PM - 05 PM" },
  { label: "05 PM - 06 PM", value: "05 PM - 06 PM" },
  ])

  useEffect(() =>{
    if(labType == "Radiology"){
      function generateTimeSlots(labStartTime, labEndTime) {
        const timeSlots = [];
        
        // Convert labStartTime and labEndTime to moment objects for easy manipulation
        let startTime = moment(labStartTime, "HH:mm");
        const endTime = moment(labEndTime, "HH:mm");
      
        // Loop until start time reaches or exceeds end time
        while (startTime.isBefore(endTime)) {
          // Format the start and end of the current slot in "hh A" format
          const slotStart = startTime.format("hh A");
          const slotEnd = startTime.clone().add(1, 'hour').format("hh A");
      
          // Push the formatted slot to the array
          const timeSlot = {
            label: `${slotStart} - ${slotEnd}`,
            value: `${slotStart} - ${slotEnd}`
          };
          timeSlots.push(timeSlot);
      
          // Move to the next hour
          startTime.add(1, 'hour');
        }
      
        return timeSlots;
      }

      const availableTimeSlots = generateTimeSlots(labStartTime, labEndTime)
      setPreferredLabTime(availableTimeSlots)
      
    }
  },[labType, labStartTime, labEndTime])

  console.log(labType, labStartTime, labEndTime, "sdvihsdouvsgudvsdhvdsv")
  const [type, setType] = useState("time");
  return (
    <div>
      <div className="flex gap-3">
        <DatePicker
          placeholder="Select Date"
          format="DD-MM-YYYY"
          disabledDate={(current) => current && current < moment().startOf('day')}
          // value={moment(preferDate).format("DD-MM-YYYY")}
          onChange={handleDateChange}
          className="w-full" />
        <div className="w-full">
          <select
            class="border border-gray rounded-md px-2 text-xs h-10 bg-white border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600 w-full"
            id="procedureStatus"
            name="procedureStatus"
            value={preferTime}
            onChange={handleChange}
          >
            <option value="">Select time </option>
            {preferredLabTime.map((res, i) => (
              <option
                key={i}
                className="py-1 text-sm text-green-600 font-semibold"
                value={res.code}
                disabled={limitTimeForLab(res)}
              >
                {res.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PreferedDateAndTime;
