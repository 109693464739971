import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  sendOtp,
  verifyLogOtp,
  setLoginModal,
  forgotPassword,
  getToken,
} from "../../Redux/Action/userActions";
import {
  patientForgetPasswordOTP,
  patientResetPasswordOTP,
} from "../../Redux/Action/UserprofileActions";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { Link } from "react-router-dom";

function ForgotPassword({
  forgotModalClose,
  forgotModal,
  showForgotModal,
  handleLoginClick,
}) {
  const { isLoading, isError, changepasswordData } = useSelector(
    (state) => state.changepassword
  );

  const dispatch = useDispatch();
  const [re] = useState(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/);
  const [phoneregex] = useState(
    /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/
  );
  const [err, setErr] = useState(false);

  const [sendOTP, setSendOTP] = useState(false);
  const [otp, setOtp] = useState("");

  const history = useHistory();
  const [isDisable, setDisable] = useState(true);
  const [email, setEmail] = useState("");
  const [patientOTP, setPatientOTP] = useState("");
  const [patientEmail, setPatientEmail] = useState("");

  const [showLoader, setLoader] = useState(false);
  const [seconds, setTimer] = useState(60);

  const myRef = useRef(null);
  const [user, setuser] = useState({
    userName: "",
    email: "",
    otp: "",
    password: "",
  });

  const setSeconds = async (e) => {
    forgetpasswordOTP(e);
  };

  const resetFields = () => {
    setErr(false);
    setSendOTP(false);
    setOtp("");
    setEmail("");
    setPatientOTP("");
    setPatientEmail("");
    setLoader(false);
    setTimer(60);
  };

  useEffect(() => {
    if (forgotModalClose) {
      resetFields();
    }
  }, [forgotModalClose]);

  useEffect(() => {
    if (sendOTP) {
      if (!seconds) return;
      const interval = setInterval(() => {
        const newTime = seconds - 1;
        setTimer(newTime);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, sendOTP]);

  const handleChange = (val) => {
    if (phoneregex.test(val)) {
      if (val.length == 10) {
        setDisable(false);
        setErr(true);
      } else {
        setDisable(true);
        setErr(false);
      }
    } else if (re.test(val)) {
      setDisable(false);
      setErr(true);
    } else {
      setDisable(true);
      setErr(false);
    }
    setEmail(val);
  };

  const sendOTPMobile = async (e) => {
    setLoader(true);
    let data;
    if (phoneregex.test(email)) {
      data = {
        mobileNumber: email,
        mobileCheck: 2,
        email: "",
        otp: otp,
      };
    } else {
      data = {
        email: email,
        otp: otp,
        mobileCheck: 2,
        mobileNumber: "",
      };
    }
    e.preventDefault();
    dispatch(patientForgetPasswordOTP(data))
      .then((res) => {
        let patientCode1 = res.data.split("?patientcode=");
        let patientCode = patientCode1[1];
        console.log(patientCode, "pateienraaaaaaaa");
        history.push(APP_ROUTES.FORGETPASSWORDRESET, {
          patientCode: patientCode,
        });
        setLoader(true);
      })
      .catch((error) => {
        console.log(err);
        setLoader(false);
        if (error?.message) {
          setErr("Invalid OTP");
          console.log(error);
        }
      });
  };

  const forgetpasswordOTP = (e) => {
    e.preventDefault();
    if (email.length == 0) {
      setSendOTP(false);
      setErr("Enter phone number or email");
      return;
    }
    if (phoneregex.test(email)) {
      if (email.length < 10) {
        setSendOTP(false);
        setErr("Mobile number should be at least 10 digits");
        return;
      }
    }else{}
    // setSendOTP(true);

    let data;
    if (phoneregex.test(email)) {
      data = {
        mobileNumber: email,
        mobileCheck: 2,
        email: "",
      };
    } else {
      data = {
        email: email,
        mobileCheck: 2,
        mobileNumber: "",
      };
    }

    dispatch(patientResetPasswordOTP(data))
      .then((res) => {
        if (res.data == 1) {
          setSendOTP(true);
          setTimer(60);
        }
      })
      .catch((error) => {
        if (error?.message) {
          if (!isNaN(email)) {
            setSendOTP(false);
            setErr("This phone number is not registered");
          } else {
            setSendOTP(false);
            setErr("This email id is not registered");
          }
        }
      });
  };

  const handleOtpChange = (e) => {
    const re = /^[0-9]*$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      e.target.value.length <= 6 && setOtp(e.target.value);
    }
  };

  return (
    <>
      <div className=" px-2">
        <div className="items-center bg-white px-2 py-6">
          <div className="flex flex-col w-full bg-white sm:px-6">
            <div className="py-8">
              {!sendOTP || err === "This email id is not registered" ? (
                <div className="flex flex-col mb-6">
                  {/* {email && <span className={`${err ? 'text-brand-lightgreen ' : ''}  font-normal text-xs  tracking-widest`}>{'Enter your Phone Number or Email Address'}</span>} */}
                  <div className="flex">
                    <input
                      type="text"
                      name="email"
                      maxLength={isNaN(email) ? 60 : 10}
                      id="sign-in-email-register"
                      className="w-full h-[50px] rounded-lg bg-White px-5 border outline-none border-gray"
                      placeholder="Enter Phone number or Registered Email to get OTP"
                      value={email}
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                    />
                  </div>
                  {err && (
                    <span className="text-red-600  font-normal text-xs  tracking-widest">
                      {err}
                    </span>
                  )}
                  <button
                    onClick={forgetpasswordOTP}
                    class="p-3 bg-primaryColor  text-White w-full mt-2 rounded-lg text-center text-base font-normal}"
                  >
                    Send OTP
                    {/* {isLoading && (
                      <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                    )} */}
                  </button>
                  {isError && (
                    <span
                      className={`text-red-600   font-normal text-xs  tracking-widest`}
                    >
                      The Mail is not registered
                    </span>
                  )}
                  {changepasswordData && (
                    <span
                      className={`text-secondaryColor  font-normal text-xs mt-2 tracking-widest`}
                    >
                      {
                        "Reset password link sent to your registered email and phone number"
                      }
                    </span>
                  )}
                </div>
              ) : (
                <div>
                  <div className={"flex flex-col mb-6"}>
                    <span>{"Enter OTP"}</span>

                    <div className="flex relative justify-center items-center">
                      <input
                        name="otp"
                        id="sign-in-email-password"
                        ref={myRef}
                        value={otp}
                        maxLength={6}
                        minLength={6}
                        onChange={handleOtpChange}
                        className="w-full h-[50px] rounded-lg outline-none bg-White px-5 border border-gray"
                        placeholder="Enter OTP"
                      />
                      <span
                        onClick={(e) => {
                          seconds <= 0 && setSeconds(e);
                        }}
                        className={`${
                          seconds == 0
                            ? "text-primaryColor cursor-pointer "
                            : "text-primaryColor cursor-not-allowed "
                        }  font-medium text-sm absolute right-1 mx-2`}
                      >
                        {`Resend ${seconds > 0 ? "in 00:" + seconds : ""}`}{" "}
                      </span>
                    </div>
                    {err && (
                      <span
                        className={`${
                          err === "OTP sent"
                            ? "text-green-500 "
                            : "text-red-600 "
                        }  font-normal text-xs  tracking-widest`}
                      >
                        {err}
                      </span>
                    )}

                    <div className="flex w-full mt-8">
                      <button
                        onClick={sendOTPMobile}
                        class="p-3 bg-primaryColor  text-White w-full  rounded-lg text-center text-base font-normal}"
                        disabled={otp.length !== 6}
                      >
                        Verify OTP
                        {showLoader && (
                          <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div className="mx-16 my-5 text-center  text-gray-primary">
                  <span className="text-primaryColor text-sm font-normal  ">
                    {"Already have an account?"}&nbsp;
                    <p
                      onClick={handleLoginClick}
                      className="font-medium text-brand-primary cursor-pointer"
                    >
                      {"Login"}
                    </p>
                  </span>
                </div>
                <div className="text-center text-brand-secondary mt-5 font-medium">
                  <Link
                    to={APP_ROUTES.CONTACTWITHUS}
                    className="text-center text-brand-secondary mt-5 font-medium"
                  >
                    Need Help? Get In Touch
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  sendOTP: (data) => dispatch(sendOtp(data)),
  verifyOTP: (data) => dispatch(verifyLogOtp(data)),
  forgotPassword: (data) => dispatch(forgotPassword(data)),
  setLoginModal: () => dispatch(setLoginModal()),
  getToken: () => dispatch(getToken()),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
