import ManIcon from "../../../Assets/Images/ManIcon.png";
import select from "../../../Assets/Images/select.svg";
import { useHistory, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Layouts from "../../Layouts";
import { MdVerified } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  bookDoctorAppointment,
  confirmPostDoctorAppointment,
} from "../../../Redux/Action/doctorAction";
import { useDispatch, useSelector } from "react-redux";
// import Addmemberpopup from "./userprofilecomponents/addmemberpopup";
import moment from "moment";
// import { USERPROFILE_ROUTES } from "../application/Router/constants/UserProfileRoutes";
// import { memberColor } from "../config/constant";
// import Down_Arrow from "../Assets/Images/Down_Arrow.svg";
// import Up_Arrow from "../Assets/Images/Up_Arrow.svg";
// import Markk from "../.../../Assets/Images/Markk.png";
import { ToastContainer, toast } from "react-toastify";
import http from "../../../Redux/Services/http-common";
import {
  walletdetails,
  walletTransactiondetails,
} from "../../../Redux/Action/UserprofileActions";
import DiscountIcon from "../../../Assets/Images/discountIcon.jpg";
// import DiscountLogoBlue from "../Assets/Images/DiscountLogoBlue.svg";
import doctpayment_package from "../../../Assets/Images/doctpaymentpackage.svg";
import {
  onApplyCoupon,
  getPatientfamilymembers,
  onPatientfamilyMembersDelete,
  // getCouponList,
  // getCouponListDoctor,
} from "../../../Redux/Action/UserprofileActions";
import DoctorService from "../../../Redux/Services/doctorService";
import ReactPixel from "react-facebook-pixel";
// import { log } from "@craco/craco/lib/logger";
import { Skeleton } from "primereact/skeleton";
import { USERPROFILE_ROUTES } from "../../PageRouting/UserProfileRoutes";

import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { DownOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import AddFamilymember from "../PostLogin/AddFamilyMember";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Loader from "../../Shared/loader";


function Payment() {
  const dispatch = useDispatch();
  const location = useLocation();
  // const patientid = location.state.patientId;
  // console.log(patientid, "patientdata in payment")
  let patientid = localStorage.getItem("patientdata");
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const patientinfo = useSelector((state) => state.patientinfo);
  const [openDialog, setDialog] = useState(false);
  const { patientinfoData, isLoading } = patientinfo;
  const { FamilymembersData } = familymemberinfo;
  const [tId, settId] = useState("");
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [Editmember, setEditmember] = useState();
  const [isCouponApply, setCouponApply] = useState(false);
  const [isMembershipApplied, setMembershipApplied] = useState(false);
  const [membershipError, setMembershipError] = useState({
    loading: false,
    error: "",
  });
  const [loading, setLoading] = useState(false);

  const [checkingMembership, setCheckingMembership] = useState(false);
  const [membershipData, setMembershipData] = useState({ membershipCard: 0 });
  const couponDoctor = useSelector((state) => state.couponDoctor);
  const { couponListDoctor } = couponDoctor;
  const [isShowCouponList, setShowCouponList] = useState(false);
  const [addressIndex, setAddressIndex] = useState(-1);
  const walletinfo = useSelector((state) => state.patientwallet);
  const [loadingDuringPayment, setLoadingDuringPayment] = useState(false);
  const [errorDuringPay, setErrorDuringPay] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedPayMode, setSelectedPayMode] = useState({
    wallet: false,
    card: false,
  });
  const [isUserReadyToPay, setIsUserReadyToPay] = useState(false);
  const [isPrescriptionUploaded, setisPrescriptionUploaded] = useState([
    { id: "" },
  ]);
  const [selectedCouponList, setSelectedCouponList] = useState([
    // {
    //   voucherCode: "CB_CONSULT10",
    //   voucherCampDescription: "10% off on doctor Consultation",
    //   isDefault: false,
    // },
    // {
    //   voucherCode: "PREDLAB1",
    //   voucherCampDescription: "10% off on doctor Consultation",
    //   isDefault: false,
    // },
  ]);
  const [selectedCouponCode, setSelectedCouponCode] = useState(null);
  const [message, setMessage] = useState("");
  const [filledMessage, setfilledMessageMessage] = useState("");
  const [availablePackage, setAvailablePackages] = useState([]);
  const search = useLocation().search;
  const search_query = new URLSearchParams(search).get("q");
  const id_search_param = new URLSearchParams(search).get("id");
  const reason_search_param = new URLSearchParams(search).get("reason");
  const rel_search_param = new URLSearchParams(search).get("rel");
  const key_search_param = new URLSearchParams(search).get("key");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [memberList, setMemeberList] = useState([]);
  let state;
  const [formData, setFormData] = useState({ couponCode: "" });
  const [oldDiscount, updateDiscount] = useState(0);
  const [oldTotal, updateTotal] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [verify, upDateverify] = useState("");
  const [couponCodeMsg, setCouponVerify] = useState("");
  const [couponApplyAmt, setCouponApplyAmt] = useState(0);
  const [complain, setComplain] = useState(
    reason_search_param ? reason_search_param : ""
  );
  const [patient, setPatient] = useState();
  const [couponList, setCouponList] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedPackageId, setSelectedPackageId] = useState({
    packageName: "",
    remaining: "",
    packageIds: "",
    packageCode: "",
  });

  if (localStorage.getItem("state")) {
    state = JSON.parse(localStorage.getItem("state"));
    // console.log(state,"dataaaa");
  } else {
    state = location.state;
    console.log(patient, state, "dataaaa1");
  }
  const goBackToHome = () => {
    history.push({ pathname: APP_ROUTES.HOME });
  };
  const footerContent = (
    <div>
      <button onClick={goBackToHome} className="btn btn-primary">
        Back to Home
      </button>
    </div>
  );
  const handleCancel = () => {
    setDialog(false);
    setIsUserReadyToPay(false);
  };

  const handleOk = () => {
    setDialog(true);
    setIsUserReadyToPay(true);
  };

  useEffect(() => {
    updateTotal(state?.amount);
  }, [state?.amount]);

  const editmembers = (e, user) => {
    e.preventDefault();
    setshowaddmemberpopup(true);
    setEditmember(user);
  };
  const deleteMembers = (e, user) => {
    e.preventDefault();
    dispatch(onPatientfamilyMembersDelete(user?.code)).then((res) => {
      dispatch(getPatientfamilymembers(userData.code));
    });
  };
  const refreshFamilyMembers = () => {
    dispatch(getPatientfamilymembers(userData.code));
  };
  useEffect(() => {
    dispatch(getPatientfamilymembers(userData.code));
  }, []);

  // useEffect(() => {
  //   console.log(patient,"patientdataa")
  //   dispatch(walletdetails(userData.code));
  //   dispatch(walletTransactiondetails(userData.code));
  //   console.log(state, "iiugiuiububuibouboubuo");
  //   let consulationType = state?.consultationsType == "V" ? "APPT_VIDEO" : "APPT_INPERSON"
  //   DoctorService.getPackagesForDoctorPayment(userData.code, consulationType, state?.userId).then(res => {
  //     console.log(res.data.data,"resu");
  //     setAvailablePackages(res.data.data)
  //   }).catch(err => {
  //     console.log(err);
  //   })
  //   // debugger
  // }, [userData.code]);

  //const { couponCode } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const coupon = async () => {
    if (couponCode == "") {
      setCouponVerify("Please select valid Coupon code");
      upDateverify(false);
      return;
    }

    let payloads = {
      customer_identifier: userData.code,
      total_transaction_cost: state?.amount,
      cart_id: state?.userId,
      transaction_id: new Date(new Date()).getTime(),
      voucher_code: couponCode,
      total_medicine_bill: 0.0,
      total_labtest_bill: 0.0,
      customer_type: "Registered",
      total_doctor_bill: state?.amount,
    };

    console.log("is request apply coupan", payloads);
    dispatch(onApplyCoupon(payloads)).then((res) => {
      if (res.data.errors == null) {
        localStorage.setItem("voucherCode", JSON.stringify(couponCode));
        localStorage.setItem(
          "voucherTransId",
          JSON.stringify(res?.data?.transactionId)
        );

        setCouponVerify("Coupon Applied");
        console.log("issss", JSON.stringify(res));
        upDateverify(true);
        setCouponApply(true);
        updateTotal(res?.data?.totalDiscountedDoctorBill);

        setCouponApplyAmt(res?.data?.totalDiscountPercentage);
        hideShow();
      } else {
        updateTotal(state?.amount);
        setCouponVerify(res.data.errors[0].message);
        upDateverify(false);
        setCouponApply(false);
      }

      console.log("is coupan response", res);
    });
  };

  const hideShow = () => {
    if (isMembershipApplied) {
      return;
    }
    if (isShowCouponList) {
      setShowCouponList(false);
    } else {
      setShowCouponList(true);
    }
  };

  // useEffect(() => {
  //   console.log("sdfnksdn", JSON.stringify(couponDoctor));

  //   if (couponListDoctor?.data?.vouchersList.length > 0) {
  //     setCouponList([...couponListDoctor.data.vouchersList]);
  //   } else {
  //     dispatch(getCouponListDoctor(userData?.code, "v_doctor")).then((res) => {
  //       setCouponList([...res.data.vouchersList]);
  //     });
  //   }
  // }, [couponListDoctor]);

  useEffect(() => {
    setCheckingMembership(true);

    dispatch(walletdetails(userData.code));
    dispatch(walletTransactiondetails(userData.code));
    console.log(state, "iiugiuiububuibouboubuo");

    let consulationType =
      state?.consultationsType == "V" ? "APPT_VIDEO" : "APPT_INPERSON";
    console.log(patient?.code, consulationType, state?.userId, "patientdataa");
    if (patientid) {
      DoctorService.getPackagesForDoctorPayment(
        patientid,
        consulationType,
        state?.userId
      )
        .then((res) => {
          console.log(res.data.data, "resu");
          setAvailablePackages(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setAvailablePackages([]);
        });
    } else {
      DoctorService.getPackagesForDoctorPayment(
        patient?.code,
        consulationType,
        state?.userId
      )
        .then((res) => {
          console.log(res.data.data, "resu");
          setAvailablePackages(res.data.data);
        })
        .catch((err) => {
          console.log(err);
          setAvailablePackages([]);
        });
    }
    // DoctorService.getPackagesForDoctorPayment(patient?.code, consulationType, state?.userId).then(res => {
    //   console.log(res.data.data,"resu");
    //   setAvailablePackages(res.data.data)
    // }).catch(err => {
    //   console.log(err);
    //   setAvailablePackages([])

    // })

    http
      .get(
        `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${
          patient?.code ? patient?.code : userData?.code
        }`
      )
      .then((res) => {
        if (res.data.data.length) {
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=APPT_VIDEO`
            )
            .then((res) => {
              if (res.data.data.length) {
                setMembershipData(res.data.data[0]);
                setMembershipApplied(false);
                updateTotal(state?.amount);
              }
              setCheckingMembership(false);
            })
            .catch((err) => {
              console.log(err, "dsvosduvhdsuvso");
              setCheckingMembership(false);
              setMembershipData({ membershipCard: 0 });
              setMembershipApplied(false);
            });
        }
      })
      .catch((err) => {
        setMembershipApplied(false);
        setMembershipData({ membershipCard: 0 });
        updateTotal(state?.amount);
        setCheckingMembership(false);
      });
  }, [userData.code, patient?.code]);

  const onClickAt = (item, i) => {
    const list = [...selectedCouponList];
    list.forEach((coupon) => {
      coupon.isDefault = false;
    });
    list[i].isDefault = true;

    setSelectedCouponList(list);

    setCouponCode(item?.voucherCode); // Update the selected coupon code state
  };

  const addSelf = () => {
    let data = {};
    data.firstName = userData.firstName;
    data.code = userData.code;
    data.relation = "Self";
    data.photoName = userData.photoName;
    data.email = userData.email;
    var list = FamilymembersData;
    list.unshift(data);
    setMemeberList(
      FamilymembersData
      // list.filter(
      //   (v, i, a) => a.findIndex((t) => t.relation === v.relation) === i
      // )
    );
    console.log(
      "uniq list",
      list.filter(
        (v, i, a) => a.findIndex((t) => t.relation === v.relation) === i
      )
    );
    setPatient(data);
  };
  console.log(memberList, "wsdcfvgbh");
  const trackPayment = async (
    patientId,
    cartList,
    transactionId,
    payUId,
    payURemarks,
    amount,
    productInfo,
    status,
    payloadResponse,
    payMode
  ) => {
    const payloadForTrackPayment = {
      patientId: patientId,
      orderId: "",
      transactionId: transactionId,
      payUId: payUId,
      payURemarks: payURemarks,
      amount: parseFloat(amount),
      productInfo: productInfo,
      status: status,
      createdBy: patientId,
      payloadResponse: payloadResponse,
      payMode: payMode,
    };
    return await http.post(
      `${process.env.REACT_APP_BASEURL}api/v1/payments/payUStatus`,
      payloadForTrackPayment
    );
  };

  useEffect(() => {
    // if(patientid){
    //   alert("hii");
    // }else
    if (userData.code) {
      addSelf();
    }
    console.log(
      localStorage.getItem("paymentStatus"),
      localStorage.getItem("trancationid"),
      localStorage.getItem("state"),
      "dssdnoidfsdhfodu"
    );
    if (
      localStorage.getItem("paymentStatus") &&
      localStorage.getItem("trancationid") &&
      localStorage.getItem("state")
    ) {
      trackPayment(
        userData.code,
        localStorage.getItem("state"),
        localStorage.getItem("trancationid"),
        "",
        "Payment done successfully",
        JSON.parse(localStorage.getItem("totalAmount")),
        "doctorAppointment",
        1,
        localStorage.getItem("patient"),
        "PayU"
      );
      redirectTo(localStorage.getItem("trancationid"));
      localStorage.removeItem("paymentStatus");
    } else if (
      localStorage.getItem("paymentStatus") === "failure" &&
      localStorage.getItem("paymentRemarks")
    ) {
      // toast(localStorage.getItem("paymentRemarks"));
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("paymentRemarks");
    }
  }, [userData, FamilymembersData]);

  // useEffect(()=>{

  // })

  const redirectTo = async (txnid) => {
    let patient = JSON.parse(localStorage.getItem("patient"));
    let state = JSON.parse(localStorage.getItem("state"));
    let data = state;
    let oldTotal = JSON.parse(localStorage.getItem("totalAmount"));
    try {
      console.log(
        JSON.parse(localStorage.getItem("state")),
        "ssdsdfhdoufsoudh"
      );
      let key_search_param = localStorage.getItem("key_search_param");
      let id_search_param = localStorage.getItem("id_search_param");
      let rel_search_param = localStorage.getItem("rel_search_param");
      let voucherCode = JSON.parse(localStorage.getItem("voucherCode"));
      let membershipId = JSON.parse(localStorage.getItem("membershipId"));
      let voucherTransId = JSON.parse(localStorage.getItem("voucherTransId"));
      let walletstatus = localStorage.getItem("walletstatus");
      let packagePayment = localStorage.getItem("payMode");
      data["txnId"] = localStorage.getItem("trancationid");
      data["consultationsReason"] = JSON.parse(
        localStorage.getItem("complain")
      );
      console.log(rel_search_param, id_search_param, "sfiwhgpwiehpiwehbvipwe");
      if (rel_search_param !== "null") {
        data["relation"] = rel_search_param;
      } else {
        data["relation"] = patient.relation;
      }
      if (id_search_param !== "null") {
        data["patientId"] = id_search_param;
      } else {
        data["patientId"] = patient.code;
      }
      data["totalAmount"] = oldTotal;
      data["payMode"] = packagePayment;
      data["voucherCode"] = voucherCode;
      data["voucherTransId"] = voucherTransId;
      data["membershipId"] = membershipId;
      data["createdBy"] = patient.code;
      data["modifiedBy"] = patient.code;
      console.log(localStorage.getItem("paymentStatus"), "paymentstatus");
      // let paymentstatusnew = localStorage.getItem("paymentStatus");
      if (localStorage.getItem("paymentStatus") === "success") {
        data["status"] = 1;
      } else if (localStorage.getItem("paymentStatus") === "pending") {
        data["status"] = 30;
      } else if (localStorage.getItem("paymentStatus") === "failure") {
        data["status"] = 31;
      } else if (walletstatus == "success") {
        data["status"] = 1;
      }
      // console.log("dsisdvoshvsiov", data , patient, JSON.parse(localStorage.getItem("trancationid")),oldTotal, packagePayment);
      if (key_search_param !== "null") {
        data["isConfirm"] = 1;
        dispatch(confirmPostDoctorAppointment(key_search_param, data));
      } else {
        dispatch(bookDoctorAppointment(data)).then((res) => {
          console.log(data, "sdvihsdovsoduvsd");
          trackPayment(
            patient.code,
            data,
            localStorage.getItem("trancationid"),
            "",
            "Doctor appointment book successfully",
            oldTotal,
            "doctorAppointment",
            3,
            localStorage.getItem("patient"),
            localStorage.getItem("payMode")
          );
          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "doctor_booked",
            ecommerce: {
              value: oldTotal,
              currency: "INR",
              items: [data],
            },
          });
        });
      }
      localStorage.removeItem("state");
      localStorage.removeItem("complain");
      localStorage.removeItem("redirectUrl");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("patient");
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("payMode");
      localStorage.removeItem("key_search_param");
      localStorage.removeItem("id_search_param");
      localStorage.removeItem("rel_search_param");
      localStorage.removeItem("voucherCode");
      localStorage.removeItem("voucherTransId");
      console.log("dsisdvoshvsiov", data);
      history.push({ pathname: APP_ROUTES.APPOINMENT_CONFIRM, state: data });
    } catch (err) {
      console.log(
        "dsisdvoshvsiov",
        localStorage.getItem("state"),
        localStorage.getItem("patient"),
        localStorage.getItem("trancationid"),
        localStorage.getItem("payMode"),
        JSON.parse(localStorage.getItem("totalAmount"))
      );

      trackPayment(
        patient.code,
        data,
        "",
        "",
        "Payment done but appointment not booked",
        oldTotal,
        "doctorAppointment",
        4,
        localStorage.getItem("patient"),
        localStorage.getItem("payMode")
      );
      localStorage.removeItem("state");
      localStorage.removeItem("complain");
      localStorage.removeItem("redirectUrl");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("patient");
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("key_search_param");
      localStorage.removeItem("id_search_param");
      localStorage.removeItem("rel_search_param");
      localStorage.removeItem("payMode");
    }
  };

  const confirmAppontmentWithoutPay = async () => {
    setLoading(true);
    try {
      if (!complain) {
        setLoading(false);
        setfilledMessageMessage("Please enter your chief health concerns.");
        let clear = setTimeout(() => {
          clearTimeout(clear);
          setfilledMessageMessage("");
        }, 2500);
        return;
      }
      
      let data = state;
      data["consultationsReason"] = complain;
      data["relation"] = patient.relation;
      data["patientId"] = patient.code;
      data["createdBy"] = patient.code;
      data["modifiedBy"] = patient.code;
      data["payMode"] = "CS_Digital";

      dispatch(bookDoctorAppointment(data))
        .then((response) => {
          console.log("API Response:", response);
          
          if (response === 1) {
              setLoading(false);
            setVisible(true); 
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("API Error:", error);
        });
    } catch (err) {
      setLoading(false);
      console.log(
        "Error:",
        localStorage.getItem("state"),
        localStorage.getItem("patient"),
        localStorage.getItem("trancationid"),
        localStorage.getItem("payMode"),
        JSON.parse(localStorage.getItem("totalAmount"))
      );

      localStorage.removeItem("state");
      localStorage.removeItem("complain");
      localStorage.removeItem("redirectUrl");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("patient");
      localStorage.removeItem("paymentStatus");
      localStorage.removeItem("trancationid");
      localStorage.removeItem("payMode");
    }
  };
  console.log(patient, "sdvsdvsdouvds", id_search_param);
  const DoPaymentwhenAmountZero = async () => {
    let data = state;
    let txnId = Math.floor(Math.random() * 300000000000);
    console.log(patient, "sdvsdvsdouvds", id_search_param);
    try {
      if (!complain) {
        setfilledMessageMessage("Please enter your chief health concerns.");
        let clear = setTimeout(() => {
          clearTimeout(clear);
          setfilledMessageMessage("");
        }, 2500);
        return;
      }
      data["txnId"] = txnId;
      data["consultationsReason"] = complain;
      if (rel_search_param !== null) {
        data["relation"] = rel_search_param;
      } else {
        data["relation"] = patient.relation;
      }
      if (id_search_param !== null) {
        data["patientId"] = id_search_param;
      } else {
        data["patientId"] = patient.code;
      }
      data["totalAmount"] = 0;
      data["payMode"] = "FULLDISCOUNT";
      data["voucherCode"] = couponCode;
      data["voucherTransId"] = null;
      data["membershipId"] = membershipData.membershipCard;
      data["createdBy"] = patient.code;
      data["modifiedBy"] = patient.code;
      data["status"] = 1;
      dispatch(bookDoctorAppointment(data)).then((res) => {
        trackPayment(
          patient.code,
          data,
          txnId,
          "",
          "Doctor appointment book successfully",
          0,
          "doctorAppointment",
          3,
          JSON.stringify(patient),
          "100% membership discount"
        );

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "doctor_booked",
          ecommerce: {
            value: 0,
            currency: "INR",
            items: [data],
          },
        });
      });
      history.push({ pathname: APP_ROUTES.APPOINMENT_CONFIRM, state: data });
    } catch (err) {
      console.log(err, "dsdsiodshoivdsjosd");
      trackPayment(
        patient.code,
        data,
        "",
        "",
        "Payment done but appointment not booked",
        0,
        "doctorAppointment",
        4,
        JSON.stringify(patient),
        "100% membership discount"
      );
    }
  };

  console.log("complain", complain);

  const DoPayment = () => {
    if (!complain) {
      setfilledMessageMessage("Please enter your chief health concerns.");
      let clear = setTimeout(() => {
        clearTimeout(clear);
        setfilledMessageMessage("");
      }, 2500);
      return;
    }
    if (couponCode == "" && !openDialog && !isMembershipApplied) {
      setDialog(true);
      return;
    }
    setIsUserReadyToPay(true);
  };

  const DoPay = async () => {
    setErrorDuringPay("");
    if (!selectedPayMode.wallet && !selectedPayMode.card) {
      toast("Please select payment mode.");
      return;
    }
    let amount = oldTotal !== 0 ? oldTotal.toString() : 1;
    let payload = {
      patientCode: userData?.code,
      doctorCode: state?.userId,
      hospitalCode: state?.hospitalId,
      locationCode: state?.locationId,
      appointmentTimeFrom: state?.fromTime,
      appointmentTimeTo: state?.toTime,
      appointmentType: state?.consultationsType,
      appointmentReason: reason_search_param ? reason_search_param : complain,
      appointmentAmount: amount,
    };
    if (selectedPayMode.wallet) {
      if (amount > walletinfo?.patientwalletinfoData?.balance) {
        toast("Balance is not sufficient.");
        return;
      } else {
        try {
          setLoadingDuringPayment(true);

          trackPayment(
            userData.code,
            payload,
            "",
            "",
            "Payment Initiated",
            amount,
            "doctorPayment",
            0,
            JSON.stringify(userData),
            "Wallet"
          );
          let data = await http.put(
            `${process.env.REACT_APP_BASEURL}api/v1/payments/wallet/appointment`,
            payload
          );
          console.log(data, "dsjfsduhfoshfoisd");
          if (data.data.statusCode == 200) {
            setLoadingDuringPayment(false);
            setIsUserReadyToPay(false);
            let d = new Date();
            let txdId = tId ? tId : d.getTime().toString();
            localStorage.setItem("totalAmount", JSON.stringify(amount));
            localStorage.setItem(
              "membershipId",
              JSON.stringify(membershipData.membershipCard)
            );
            localStorage.setItem("state", JSON.stringify(state));
            localStorage.setItem("complain", JSON.stringify(complain));
            localStorage.setItem("redirectUrl", APP_ROUTES.PAYMENT);
            localStorage.setItem("patient", JSON.stringify(patient));
            localStorage.setItem(
              "trancationid",
              JSON.stringify(data.data.data.transcationId)
            );
            localStorage.setItem("payMode", "CureBay wallet");
            localStorage.setItem(
              "paymentRemarks",
              "Your Order placed successfully"
            );
            localStorage.setItem("key_search_param", key_search_param);
            localStorage.setItem("id_search_param", id_search_param);
            localStorage.setItem("rel_search_param", rel_search_param);
            localStorage.setItem("walletstatus", "success");
            trackPayment(
              userData.code,
              payload,
              data.data.data.transcationId,
              "",
              "Payment done successfully",
              amount,
              "doctorAppointment",
              1,
              JSON.stringify(userData),
              "Wallet"
            );
            redirectTo(data.data.data.transcationId);
          } else {
            setLoadingDuringPayment(false);
            trackPayment(
              userData.code,
              payload,
              "",
              "",
              "Payment failed",
              amount,
              "doctorAppointment",
              2,
              JSON.stringify(userData),
              "Wallet"
            );
            setErrorDuringPay("Something went wrong try again.");
          }
        } catch (error) {
          console.error("An error occurred during payment:", error);
          setErrorDuringPay(
            "Something went wrong while processing your payment. Please try again later."
          );
          setLoadingDuringPayment(false);
        }
      }
    }

    if (selectedPayMode.card) {
      let d = new Date();
      let txdId = tId ? tId : d.getTime().toString();
      let amount = oldTotal !== 0 ? oldTotal.toString() : 1;
      console.log("amount", amount);
      let firstName = patient.firstName.split(/\s/).join("");
      localStorage.setItem("totalAmount", JSON.stringify(amount));
      localStorage.setItem(
        "membershipId",
        JSON.stringify(membershipData.membershipCard)
      );
      localStorage.setItem("state", JSON.stringify(state));
      localStorage.setItem("complain", JSON.stringify(complain));
      localStorage.setItem("redirectUrl", APP_ROUTES.PAYMENT);
      localStorage.setItem("patient", JSON.stringify(patient));
      localStorage.setItem("key_search_param", key_search_param);
      localStorage.setItem("payMode", "CC");
      localStorage.setItem("id_search_param", id_search_param);
      localStorage.setItem("rel_search_param", rel_search_param);
      trackPayment(
        userData.code,
        payload,
        "",
        "",
        "Payment Initiated",
        amount,
        "doctorAppointment",
        0,
        JSON.stringify(userData),
        "Payu"
      );
      const url =
        process.env.REACT_APP_PAYU_BASEURL +
        `patientId=${patient.code}&amount=${amount}&firstname=${firstName}&email=${patient.email}&phone=${patient.mobile}&productinfo=doctorpayment&udf1=Patient&service_provider=payu_paisa`;
      window.location.replace(url);

      // redirectAfterTxn("7575757858767");
    }
  };
  // if (!id_search_param) {
  //   setSelectedPatient(user.code);
  //   selectPatient(user);
  // }
  console.log(patient, "asdfghjk");
  const handleClick = (user) => {
    if (patientid) {
      selectPatient(user);
    } else {
      if (!id_search_param) {
        setSelectedPatient(user.code);
        selectPatient(user);
      }
    }

    // Add additional logic here if needed for other cases
  };

  // const selectPatient = (data, user) => {
  //   console.log("iohohiohoih", data);
  //   console.log("User data:", user);
  //   setPatient(data);
  // };

  const selectPatient = (user) => {
    console.log("User data:", selectedUserData);
    setPatient(user);
  };

  const handleUserClick = (user) => {
    if (!id_search_param) {
      setSelectedUserData(user.data);
      setSelectedPatient(user);
      selectPatient(user);
    }
  };
  console.log(patient, "qwerty123");

  //   useEffect(() => {

  //     const specificUserData = memberList.find(user => user.code === patientid);
  // console.log(specificUserData,"specificUserData")
  // // setPatient(specificUserData);

  //   });
  // useEffect(() => {
  //   setMemeberList(FamilymembersData );
  //   const specificUserData = memberList.find(user => user.code === patientid);
  //  // console.log(specificUserData, "specificUserData");
  //   console.log(FamilymembersData,"specificUserData");
  //   // Check if specificUserData is undefined and memberList is populated
  //   if (specificUserData === undefined && memberList.length > 0) {
  //     // Wait for the next render to try again

  //   }
  // }, [memberList, patientid]);

  useEffect(() => {
    let timeoutId;
    if (patientid) {
      const fetchData = () => {
        const specificUserData = memberList.find(
          (user) => user.code === patientid
        );
        console.log(specificUserData, "specificUserData");
        setPatient(specificUserData);
        // Continue with your logic here
        // ...
      };

      // Add a delay of 1 or 2 seconds (adjust the duration as needed)
      timeoutId = setTimeout(fetchData, 1000); // 1000 milliseconds = 1 second

      return () => {
        clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the dependency changes
      };
    }
  }, [memberList, patientid]);

  const redirectTos = (event) => {
    event.preventDefault();
    setshowaddmemberpopup(true);
    // history.push(USERPROFILE_ROUTES.ADDDMEMBERS);
  };

  console.log(state, "dvbdbvoudbu");

  useEffect(() => {
    dispatch(getPatientfamilymembers(userData.code));
  }, [showaddmemberpopup, patientinfoData, isLoading]);

  // useEffect(() => {
  //   if (state) {
  //     ReactPixel.track("Proceed_To_Payment", {
  //       value: state.amount,
  //       currency: "INR",
  //       item_name: state.userName,
  //     });
  //   }
  // }, [state]);

  const onSelectPackage = (res) => {
    if (selectedPackageId.packageIds == res.id) {
      setSelectedPackageId("");
    } else {
      setSelectedPackageId({
        packageIds: res.id,
        packageName: res.packageName,
        remaining: res.remaining,
        packageCode: res.packageCode,
      });
    }
  };

  const onRedeemNow = async () => {
    try {
      let data = state;
      data["remaining"] = selectedPackageId.remaining;
      data["packageIds"] = selectedPackageId.packageIds;
      data["packageStatus"] = 1;
      data["packageName"] = selectedPackageId.packageName;
      data["packageCode"] = selectedPackageId.packageCode;
      data["consultationsReason"] = complain;
      // data["modifiedBy"] = patient.code
      data["email"] = patient.email ? patient.email : userData.email;
      data["patientId"] = patient.code;
      data["amount"] = null;
      data["patientName"] = patient?.firstName + patient?.lastName;
      data["createdBy"] = patient.code;
      data["modifiedBy"] = patient.code;
      data["status"] = 1;
      console.log(data, "sadnsdlsoidbobvwdosd", patient);
      dispatch(bookDoctorAppointment(data));
      history.push({ pathname: APP_ROUTES.APPOINMENT_CONFIRM, state: data });
    } catch (err) {}
  };

  const handleMemberShipClick = () => {
    if (isCouponApply) {
      return;
    }
    if (!isMembershipApplied) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${membershipData.membershipCard}&serviceType=doctor&cartId=${state?.userId}&amount=${oldTotal}`
        )
        .then((res) => {
          console.log(res, "dsvdsovusdvsd");
          updateTotal(res.data.data.paidAmount);
          setMembershipApplied(true);
          setMembershipError({
            loading: false,
            error: "",
          });
        })
        .catch((err) => {
          if (err.response.status == 400) {
            setMembershipError({
              loading: false,
              error:
                "Your membership benefit is exhausted with this Doctor, please try consultation with another Doctor to avail membership discount",
            });
          } else {
            setMembershipError({
              loading: false,
              error: err.response.data.message,
            });
          }

          setMembershipApplied(false);
        });
    } else {
      updateTotal(state?.amount);
      setMembershipApplied(false);
    }
  };

  console.log(patient, "selectedPackageId");
  console.log(membershipData, "Membership_data");

  return (
    <>
      <Layouts>
        <ToastContainer />
        <Dialog header="Booking Confirmation" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
      <p className="m-0">
        Our Customer support will contact you shortly for confirming the booking.
      </p>
    </Dialog>

    {loading && (
         <Loader />
         )}
        <div className="block">
          <section className="bg-lightGray">
            <div className="container mx-auto ">
              <div className="w-full bg-primaryColor rounded-md px-8 py-4 mb-5">
                <div className=" md:text-xl text-White">
                  <Link to="/find-doctors"> Doctors </Link>
                  <RightOutlined className="text-base" />
                  <span className="cursor-pointer text-secondaryColor pl-2">
                    Payment
                  </span>
                </div>
              </div>
              <div className="md:flex gap-5">
                <div className="md:w-[60%] bg-White py-5 px-8 rounded-lg">
                  <div className="flex justify-between items-center mb-5">
                    <div className="flex items-center gap-3">
                      <MdVerified className="fill-secondaryColor" />
                      <p className="font-medium md:text-xl text-primaryColor">
                        Select a Patient
                      </p>
                    </div>
                    <p
                      onClick={(e) => editmembers(e, "")}
                      className="text-secondaryColor text-sm underline font-medium cursor-pointer"
                    >
                      Add a Patient
                    </p>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    {memberList.map((user, i) => (
                      <div
                        key={i}
                        className=" cursor-pointer"
                        // onClick={() => id_search_param ? null : selectPatient(user)}
                        onClick={
                          patientid
                            ? ""
                            : () =>
                                id_search_param ? null : selectPatient(user)
                        }

                        // onClick={() => handleClick(user)}
                        // onClick={() => {
                        //   if (!id_search_param) {
                        //     setSelectedPatient(user.code);
                        //     selectPatient(user);
                        //   }
                        // }}
                        // onClick={patientid ? () => handleUserClick(user) : undefined}
                      >
                        <div className="border border-gray p-3 rounded-lg h-full">
                          <div className="flex items-center ">
                            {patientid ? (
                              <input
                                type="radio"
                                checked={
                                  user.code ===
                                    (id_search_param
                                      ? id_search_param
                                      : patient.code) || user.code === patientid
                                }
                                className="form-radio  mr-2"
                                name="accountType"
                                value="personal"
                                disabled={
                                  user.code !==
                                    (id_search_param
                                      ? id_search_param
                                      : patient.code) && user.code !== patientid
                                }
                                onClick={() => handleUserClick(user)}
                              />
                            ) : (
                              <input
                                type="radio"
                                checked={
                                  user.code ===
                                  (id_search_param
                                    ? id_search_param
                                    : patient.code)
                                }
                                className="form-radio  mr-2"
                                name="accountType"
                                value="personal"
                                onClick={() => handleUserClick(user)}
                              />
                            )}
                            {/* <input
                        type="radio"
                      checked={user.code === (id_search_param ? id_search_param : patient.code) || user.code === patientid}

                      checked={user.code === (id_search_param ? id_search_param : patient.code)}

                        className="form-radio mt-4 mr-2"
                        name="accountType"
                        value="personal"
                      disabled={user.code !== (id_search_param ? id_search_param : patient.code) && user.code !== patientid}
                       onClick={() => handleUserClick(user)}
                      /> */}
                            <div className="w-10 h-10  bg-lightBlue">
                              <img
                                src={
                                  user.photoName ? `${user.photoName}` : ManIcon
                                }
                                alt={user?.name}
                                className="w-full h-full rounded-full"
                              />
                            </div>
                            <div className="ml-2">
                              <p
                                className="text-sm font-medium text-primaryColor"
                                style={{ width: "max-content" }}
                              >
                                {user.name}
                              </p>
                              {/* <p className=" pl-3 text-xs text-gray-400">
                          {user.relation}
                        </p> */}

                              <div
                              // className={`h text-center px-2 flex justify-center bg-brand-${
                              //   memberColor[user?.relation]
                              // } rounded-2xl`}
                              >
                                <p className="text-White w-fit bg-primaryColor text-center px-3 text-xs rounded-xl">
                                  {user?.relation}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="relative mt-5">
                    <label
                      for="chemicalAllergy"
                      className="text-primaryColor text-sm md:text-base"
                    >
                      Chief Complaints <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="flex">
                      {/* <lable className="py-2 w-36 text-xs text-gray-700 border-b-2 border-gray-300">Enter name of allergy </lable> */}
                      <textarea
                        id="chemicalAllergy"
                        name="chemicalAllergy"
                        maxLength="100"
                        type="text"
                        className="border border-gray w-full text-sm h-[100px] rounded-lg mt-3 p-3"
                        onChange={(e) => {
                          if (!reason_search_param) {
                            setComplain(e.target.value);
                          }
                        }}
                        placeholder="To book appointment, Please enter your Symptoms/Complaints"
                        value={complain}
                      />
                    </div>
                    <p className="hidden sm:flex font-medium text-sm text-red-600">
                      {filledMessage}
                    </p>
                    {selectedPackageId.packageIds ? (
                      <div className="sm:flex font-medium text-sm text-red-600">
                        {complain == ""
                          ? "Please enter your chief health concerns."
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {availablePackage?.length ? (
                    <>
                      <div className="ml-2 mt-4 font-bold text-md ">
                        Available Packages to Redeem
                      </div>
                      <div className="flex my-2 mx-2  overflow-auto">
                        {/* {availablePackage?.length} */}
                        {availablePackage.map((res) => {
                          return (
                            <div
                              style={{
                                minWidth: "350px",
                                backgroundColor: "rgba(188, 235, 255, 0.21)",
                                boxShadow: "0 9px 7px 0 rgba(0, 0, 0, 0.04)",
                              }}
                              className={` cursor-pointer border rounded mr-2 
                      ${
                        res.id == selectedPackageId.packageIds
                          ? " border-b-4 border-brand-biscay3"
                          : ""
                      }`}
                              onClick={() => onSelectPackage(res)}
                            >
                              <div className=" flex p-3">
                                <div className="flex w-3/5 space-x-2">
                                  <img
                                    src={doctpayment_package}
                                    className="mb-7"
                                  />
                                  <div className=" flex flex-col space-y-2">
                                    <h1
                                      className=" font-semibold text-sm mt-1 "
                                      style={{ color: " rgba(0, 65, 113, 1)" }}
                                    >
                                      {" "}
                                      {res.packageName}{" "}
                                    </h1>
                                    <p className=" font-normal text-sm ">
                                      {" "}
                                      Remaining :
                                      <span className="font-semibold">
                                        {""} {res.remaining}
                                      </span>{" "}
                                    </p>
                                  </div>
                                </div>
                                <div className=" flex w-3/5 text-sm justify-end mb-1 items-end">
                                  <p className="font-normal text-sm">
                                    {" "}
                                    Discounted Price :{" "}
                                    <span className="font-semibold">
                                      {""}
                                      {res.discountAmount}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>{" "}
                    </>
                  ) : null}
                  {!selectedPackageId.packageIds ? (
                    <div className="flex justify-end">
                      {" "}
                      <button
                        onClick={
                          search_query == "confirm"
                            ? confirmAppontmentWithoutPay
                            : oldTotal
                            ? DoPayment
                            : DoPaymentwhenAmountZero
                        }
                        disabled={message ? true : false}
                        className="hidden md:block bg-primaryColor min-w-[150px] text-white  py-2 px-4 rounded-lg text-sm mt-8 disabled:opacity-50"
                        style={{ color: "#ffff" }}
                      >
                        {search_query == "confirm"
                          ? "Confirm"
                          : oldTotal
                          ? "Pay"
                          : "Book Appointment"}
                      </button>{" "}
                    </div>
                  ) : (
                    <div className="flex justify-end">
                      <button
                        onClick={onRedeemNow}
                        disabled={complain === "" ? true : false}
                        className="hidden md:block bg-brand-secondary mr-6 text-white  py-2 px-4 rounded text-sm mt-4 disabled:opacity-50"
                      >
                        Redeem Now
                      </button>{" "}
                    </div>
                  )}

                  <div className="flex justify-end  font-medium text-sm text-green-600">
                    {message}
                  </div>
                </div>
                {/* {availablePackage?.length ?""} */}
                {checkingMembership ? (
                  <div className={`w-[40%] md:w-4/12 md:mr-6 mt-3 `}>
                    {/* className={`list ${Menu.gap ? "top1" : "top2"} ${index === 0 && "bgclr"} `} */}
                    <div
                      className=" bg-white-600 w-full   rounded-lg  py-3 px-7 antialiased "
                      style={{
                        boxShadow: "0px 13px 20px rgba(0, 0, 0, 0.05)",
                        background: "rgba(188, 235, 255, 0.21)",
                      }}
                    >
                      <p className="text-sm lg:text-base font-semibold text-gray-800 mb-3">
                        Select Coupon
                      </p>
                      <Skeleton
                        className="mb-2 rounded-lg"
                        height="2rem"
                      ></Skeleton>
                      <div className="bg-White rounded-lg px-7 py-3">
                        <p
                          className="text-sm lg:text-sm font-semibold text-gray-800 leading-24 mb-2 "
                          style={{ color: "#000000" }}
                        >
                          PAYMENT DETAILS
                        </p>
                        <Skeleton
                          className="mb-2 rounded-lg"
                          height="9rem"
                        ></Skeleton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={`md:w-[40%] md:mr-6 mt-3 `}>
                    <div
                      className=" bg-white-600 w-full   rounded-lg  py-3 px-7 antialiased "
                      style={{
                        boxShadow: "0px 13px 20px rgba(0, 0, 0, 0.05)",
                        background: "rgba(188, 235, 255, 0.21)",
                      }}
                    >
                      {isShowCouponList ? (
                        <p className="text-sm lg:text-base font-semibold text-gray-800 mb-3">
                          Select Coupon
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="flex gap-4 w-full"></div>
                      {selectedPackageId.packageIds ? (
                        <>
                          {search_query == "confirm" ? null : (
                            <div className="w-full">
                              <button
                                className={`btn px-6 cursor-pointer flex justify-between items-center bg-White btn-primary w-full  text-sm h-12 ${
                                  isShowCouponList
                                    ? "rounded-tl-lg rounded-tr-lg "
                                    : "rounded-lg"
                                } text-red font-bold`}
                                //   onClick={(e) => {
                                //   hideShow();
                                // }}
                              >
                                <div>
                                  <div className="flex">
                                    <img
                                      src={DiscountIcon}
                                      style={{ height: "20px" }}
                                      className="mr-4"
                                    />
                                    <span>Avail Coupons</span>
                                  </div>
                                  {isCouponApply ? (
                                    <div className="text-green-600 text-xs pl-8 pt-1">
                                      Coupon code applied{" "}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {isShowCouponList ? (
                                  <UpOutlined />
                                ) : (
                                  <DownOutlined />
                                )}
                              </button>
                              {isShowCouponList && (
                                <div
                                  className={`w-full overflow-y-scroll overflow-x-hidden bg-white rounded-bl-lg rounded-br-lg`}
                                >
                                  <div className="bg-white pb-4 sticky z-20 top-0">
                                    <div className="flex flex-row space-x-2 px-4 mt-4 ">
                                      <input
                                        name="couponCode"
                                        placeholder="Select Coupon Code"
                                        value={couponCode}
                                        onChange={(e) =>setCouponCode(e.target.value.toUpperCase())}
                                        className=" bg-white   font-medium text-gray-500 h-12 text-left pl-2 py-2 w-full lg:w-full border border-gray-200 rounded-lg text-sm "
                                      />
                                      <button
                                        className="btn btn-primary mt-0 absolute rounded-lg p-2 text-black right-4 font-semibold text-sm h-12 z-30"
                                        style={{ color: "#66B889" }}
                                        onClick={coupon}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                    <span
                                      className={
                                        verify
                                          ? `text-green-600 mt-3  text-xs px-4`
                                          : `text-red-600 mt-3 text-xs px-4 text-center`
                                      }
                                    >
                                      {couponCodeMsg}
                                    </span>
                                  </div>
                                  <div className="flex flex-col gap-4 max-h-96 mx-4 z-10 ">
                                    {couponList.map((item, i) => {
                                      return (
                                        <div
                                          key={i}
                                          className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full "
                                          style={{
                                            border:
                                              "1px solid rgba(0, 93, 141, 0.15) ",
                                            background: !item?.isDefault
                                              ? "white"
                                              : "     rgba(25, 135, 84,0.23)",
                                          }}
                                          onClick={() => onClickAt(item, i)}
                                        >
                                          <div className="bg-green  justify-center h-min-20 rounded-lg">
                                            <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                                              <h3 className=" px-4 pb-2 pt-1 w-2/3 ">
                                                <b className="border-dashed border-2 p-1">
                                                  {item.voucherCode}
                                                </b>
                                              </h3>
                                            </div>
                                            <p className="mx-4 p-1 text-grey font-normal text-sm">
                                              {item.voucherCampDescription}
                                            </p>

                                            <div
                                              className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6"
                                              style={{
                                                borderRight:
                                                  "1px solid rgba(0, 93, 141, 0.15)",
                                              }}
                                            ></div>
                                            <div
                                              className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6 "
                                              style={{
                                                borderLeft:
                                                  "1px solid rgba(0, 93, 141, 0.15)",
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {search_query == "confirm" ? null : (
                            <div className="w-full">
                              {(!membershipData?.membershipCard ||
                                membershipData?.membershipCard?.length ===
                                  0) && (
                                <button
                                  className={`btn px-6 cursor-pointer flex justify-between items-center bg-White btn-primary w-full text-sm h-12 ${
                                    isShowCouponList
                                      ? "rounded-tl-lg rounded-tr-lg "
                                      : "rounded-lg"
                                  } text-red font-bold`}
                                  onClick={(e) => {
                                    hideShow();
                                  }}
                                >
                                  <div>
                                    <div className="flex">
                                      <img
                                        src={DiscountIcon}
                                        style={{ height: "20px" }}
                                        className="mr-4"
                                      />
                                      <span>Avail Coupons</span>
                                    </div>

                                    {isCouponApply ? (
                                      <div className="text-green-600 text-xs pl-8 pt-1">
                                        Coupon code applied{" "}
                                      </div>
                                    ) : null}
                                  </div>
                                  {isShowCouponList ? (
                                    <UpOutlined />
                                  ) : (
                                    <DownOutlined />
                                  )}
                                </button>
                              )}

                              {isShowCouponList && (
                                <div
                                  className={`w-full overflow-y-scroll overflow-x-hidden bg-white rounded-bl-lg rounded-br-lg`}
                                >
                                  <div className="bg-white pb-4 sticky z-20 top-0">
                                    <div className="flex flex-row space-x-2 px-4 mt-4 ">
                                      <input
                                        name="couponCode"
                                        placeholder="Select Coupon Code"
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                                        className=" bg-white   font-medium text-gray-500 h-12 text-left pl-2 py-2 w-full lg:w-full border border-gray-200 rounded-lg text-sm "
                                      />
                                      <button
                                        className="btn btn-primary mt-0 absolute rounded-lg p-2 text-black right-4 font-semibold text-sm h-12 z-30"
                                        style={{ color: "#66B889" }}
                                        onClick={coupon}
                                      >
                                        Apply
                                      </button>
                                    </div>
                                    <span
                                      className={
                                        verify
                                          ? `text-green-600 mt-3  text-xs px-4`
                                          : `text-red-600 mt-3 text-xs px-4 text-center`
                                      }
                                    >
                                      {couponCodeMsg}
                                    </span>
                                  </div>
                                  <div className="flex flex-col gap-4 max-h-96 mx-4 z-10 ">
                                    {selectedCouponList.map((item, i) => (
                                      <div
                                        key={i}
                                        className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full"
                                        style={{
                                          border:
                                            "1px solid rgba(0, 93, 141, 0.15)",
                                          background: !item.isDefault
                                            ? "white"
                                            : "rgba(25, 135, 84, 0.23)",
                                        }}
                                        onClick={() => onClickAt(item, i)}
                                      >
                                        <div className="bg-green justify-center h-min-20 rounded-lg">
                                          <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                                            <h3 className="px-4 pb-2 pt-1 w-2/3">
                                              <b className="border-dashed border-2 p-1">
                                                {item.voucherCode}
                                              </b>
                                            </h3>
                                          </div>
                                          <p className="mx-4 p-1 text-grey font-normal text-sm">
                                            {item.voucherCampDescription}
                                          </p>
                                          <div
                                            className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6"
                                            style={{
                                              borderRight:
                                                "1px solid rgba(0, 93, 141, 0.15)",
                                            }}
                                          ></div>
                                          <div
                                            className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6"
                                            style={{
                                              borderLeft:
                                                "1px solid rgba(0, 93, 141, 0.15)",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {search_query !== "confirm" &&
                      selectedPackageId.packageIds ? (
                        <>
                          <button
                            className={` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`}
                            style={{
                              backgroundColor: "rgb(255 255 255 / 86%)",
                            }}
                          >
                            {membershipError.loading && (
                              <div
                                className="absolute w-full flex justify-center"
                                style={{
                                  backgroundColor: "rgb(255 255 255 / 86%)",
                                }}
                              >
                                <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                              </div>
                            )}
                            <div className="flex justify-between w-full items-center">
                              <span className="text-sm font-bold mr-4">
                                Get Membership Discount
                              </span>

                              <input
                                className="cursor-pointer divisiondisable"
                                disabled="disabled"
                                type="checkbox"
                              />
                            </div>
                            <div className="text-xs w-full font-medium text-left mt-1 text-red-600">
                              {membershipError.error}
                            </div>
                          </button>
                        </>
                      ) : (
                        <>
                          {search_query !== "confirm" &&
                          membershipData?.membershipCard?.length ? (
                            <button
                              onClick={handleMemberShipClick}
                              style={
                                isMembershipApplied
                                  ? {
                                      backgroundColor: "#66B889",
                                      color: "#ffff",
                                    }
                                  : {
                                      backgroundColor: "#ffff",
                                      color: "#1F2937",
                                    }
                              }
                              className={` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`}
                            >
                              {membershipError.loading && (
                                <div
                                  className="absolute w-full flex justify-center"
                                  style={{
                                    backgroundColor: "rgb(255 255 255 / 86%)",
                                  }}
                                >
                                  <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                                </div>
                              )}
                              <div className="flex justify-between w-full items-center">
                                <span className="text-sm font-bold mr-4">
                                  Get Membership Discount
                                </span>

                                <input
                                  checked={
                                    isMembershipApplied > 0 ? true : false
                                  }
                                  className="cursor-pointer"
                                  onChange={handleMemberShipClick}
                                  type="checkbox"
                                />
                              </div>
                              <div className="text-xs w-full font-medium text-left mt-1 text-red-600">
                                {membershipError.error}
                              </div>
                            </button>
                          ) : null}
                        </>
                      )}

                      {/* { membershipData?.membershipCard?.length ? <button onClick={handleMemberShipClick} style = {isMembershipApplied ? {backgroundColor: "#66B889", color: "#ffff"} : {backgroundColor: "#ffff", color: "#1F2937"}} className= {` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`} >
          {membershipError.loading && <div className="absolute w-full flex justify-center" style={{backgroundColor: "rgb(255 255 255 / 86%)"}} >
              <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
              </div>}
              <div className=  "flex justify-between w-full items-center"  >
                <span className="text-sm font-bold mr-4" >Get Membership Discount</span>
                
                <input checked = {isMembershipApplied > 0 ? true : false} className = "cursor-pointer" onChange = {handleMemberShipClick} type= "checkbox" /> 
              </div>
              <div className="text-xs w-full font-medium text-left mt-1 text-red-600" >{membershipError.error}</div>
              </button> : null }  */}

                      <div
                        className=" bg-White-600 w-full bg-White  rounded-lg  py-3 px-4 md:px-7 antialiased mt-4 "
                        style={{
                          boxShadow: "0px 13px 20px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <div className=" flex-wrap">
                          <p
                            className="text-sm lg:text-sm font-semibold text-gray-800 leading-24 mb-2 "
                            style={{ color: "#000000" }}
                          >
                            PAYMENT DETAILS
                          </p>
                          <div>
                            <div className="flex justify-between">
                              <p
                                className="text-sm pt-2"
                                style={{ width: "max-content" }}
                              >
                                {state?.userSalutation + " " + state?.userName}
                              </p>
                              <p className="text-sm pl-1 pt-3 text-secondaryColor">
                                {moment(
                                  state?.whenAppointment,
                                  "MM/DD/YYYY"
                                ).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <div className="flex justify-between mt-2">
                              <div
                              // className={`h-4 text-center px-2 flex justify-center bg-brand-${
                              //   memberColor[patient?.relation]
                              // } rounded-2xl`}
                              >
                                <p className="text-sm rounded-lg px-3 text-White bg-primaryColor">
                                  {patient?.relation}{" "}
                                </p>
                              </div>
                              <p className="text-sm mr-12">
                                {state?.consultationsType === "V"
                                  ? "Online"
                                  : "InPerson"}
                              </p>
                              <p className="text-sm font-medium">
                                {moment(state?.fromTime, 'HH:mm').format('hh:mm A')}
                               
                              </p>
                            </div>
                            <hr className="mt-2 mb-2" />

                            <div className="flex justify-between">
                              <p className="text-sm font-normal">
                                Consultation Fee
                              </p>
                              <p className="text-sm font-normal">
                                ₹ {state?.amount ? state?.amount : 1}/-
                              </p>
                            </div>
                            {state?.amount - oldTotal > 0 && (
                              <div className="flex justify-between mt-3">
                                <p className="text-green-900 text-sm font-medium ">
                                  Discount
                                </p>
                                <p
                                  className="text-green-900 text-sm font-medium"
                                  name="Discount"
                                >
                                  - ₹ {(state?.amount - oldTotal).toFixed(2)}/-{" "}
                                </p>
                              </div>
                            )}
                            <div className="flex justify-between mt-3">
                              <p className="text-brand-secondary"> Total</p>
                              <p className="text-brand-secondary font-medium">
                                ₹ {oldTotal}/-
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col md:w-[40%] justify-center md:hidden">
                  {!selectedPackageId.packageIds ? (
                    <button
                      onClick={
                        search_query == "confirm"
                          ? confirmAppontmentWithoutPay
                          : DoPayment
                      }
                      disabled={message ? true : false}
                      className=" bg-primaryColor text-White  py-3 w-full rounded text-sm mt-4 disabled:opacity-50"
                    >
                      {search_query == "confirm"
                        ? "Confirm"
                        : oldTotal
                        ? "Pay"
                        : "Book Appointment"}
                    </button>
                  ) : (
                    <button
                      onClick={onRedeemNow}
                      disabled={complain === "" ? true : false}
                      className=" bg-primaryColor text-White  py-3 w-full rounded text-sm mt-4 disabled:opacity-50"
                    >
                      Redeem Now
                    </button>
                  )}
                  <div className="flex justify-end  font-medium text-sm text-green-600">
                    {message}
                  </div>
                  <p className="flex font-medium justify-center text-center text-sm text-red-600">
                    {filledMessage}
                  </p>
                </div>
              </div>
              <br />
              {/* {showaddmemberpopup ? (
          <Addmemberpopup
            closePopup={() => setshowaddmemberpopup(!showaddmemberpopup)}
            saveMessage = {(message) =>{
              setshowaddmemberpopup(!showaddmemberpopup);
            }}
          ></Addmemberpopup>
        ) : null} */}
            </div>
          </section>
        </div>
      </Layouts>

      <Modal
        title="Add a Family Member"
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshFamilyMembers();
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <AddFamilymember
          editmembers={Editmember}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            toast(message);
            refreshFamilyMembers();
          }}
        />
      </Modal>
      <Modal
        visible={openDialog}
        showHeader={true}
        resizable={false}
        draggable={false}
        title="Payment"
        modal={true}
        footer={false}
        style={{ width: "500px" }}
        //  className="w-100 h-auto"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <>
          <div className="text-center">
            <div className="flex flex-col md:flex-row items-center justify-around mt-5">
              {/* <button
                className="my-2 w-40 bg-primaryColor text-sm h-6 rounded-md text-White font-normal "
                onClick={() => setDialog(false)}
              >
                Select Coupon
              </button> */}

              <button
                className="my-2 w-60 bg-primaryColor text-sm h-6 rounded-md text-White font-normal "
                onClick={DoPayment}
                disabled={complain === "" ? true : false}
              >
                Proceed to Payment
              </button>
            </div>
          </div>
        </>
      </Modal>

      {isUserReadyToPay ? (
        <Modal
          title="Select Payment Mode"
          visible={isUserReadyToPay}
          resizable={false}
          draggable={false}
          modal={true}
          className="w-11/12 md:w-96"
          footer={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div>
            <div>
              <div
                className="flex  font-medium text-sm justify-between items-center py-2 px-6 rounded mb-2 cursor-pointer"
                style={{
                  backgroundColor: "#e5e6e7",
                  border: selectedPayMode.wallet ? "2px solid #66B889" : "",
                }}
                onClick={() =>
                  setSelectedPayMode({ wallet: true, card: false })
                }
              >
                CureBay wallet - Rs{" "}
                {walletinfo?.patientwalletinfoData?.balance &&
                  walletinfo?.patientwalletinfoData?.balance.toFixed(2)}{" "}
                <i className="pi pi-angle-right"></i>
              </div>
            </div>
            <div
              className="flex justify-between  font-medium text-sm items-center py-2 px-6 rounded cursor-pointer"
              style={{
                backgroundColor: "#e5e6e7",
                border: selectedPayMode.card ? "2px solid #66B889" : "",
              }}
              onClick={() => setSelectedPayMode({ wallet: false, card: true })}
            >
              Debit/Credit Cards/UPI & Others{" "}
              <i className="pi pi-angle-right"></i>
            </div>
            <div className="text-center">
              <button
                className=" font-normal text-xs py-2 px-6 rounded mt-4"
                style={{ backgroundColor: "#66B889", color: "#ffff" }}
                onClick={DoPay}
              >
                Pay
                {loadingDuringPayment && (
                  <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                )}
              </button>
            </div>
            {errorDuringPay.length > 0 ? (
              <div style={{ color: "red" }} className="font-normal text-sm">
                {" "}
                {errorDuringPay}{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
export default Payment;