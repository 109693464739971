import React, { useEffect, useState } from "react";
import { Base64 } from "js-base64";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import { patientResetPassword } from "../../Redux/Action/UserprofileActions";
import { message } from "antd";
import Layouts from "../Layouts";
import { APP_ROUTES } from "../PageRouting/AppRoutes";

function ForgotPasswordReset(props) {


  const patientinfo = useSelector((state) => state.patientinfo);
  const [msg, setMsg] = useState("");
  const [color, setColor] = useState("red");
  const [showLoader, setLoader] = useState(false);

  const { patientinfoData } = patientinfo;
  const dispatch = useDispatch();
  const history = useHistory();
  const [regexP] = useState(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
  );

  console.log(patientinfo,"patientInfo");

  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [newPasswordShown, setnewPasswordShown] = useState(false);
  const [validationError, setvalidationError] = useState("");
  const [confirmValidation, setConfirmValidation] = useState("");
  const [newPassValidation, setNewPassValidation] = useState("");
  const location = useLocation();
  const params = location.state.patientCode

  console.log("patient code in next page", params)
  // console.log(params.get("patientcode") , "sdvbsoudvhosdu")

  const handlePasswordChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value.trim(),
    });
    passwordValidate(e.target.value);

    if (e.target.value.trim().length == 0) {
      setConfirmValidation("");
      return;
    }
  };

  const handlePasswordChangeNewPassword = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value.trim(),
    });
    passwordValidateNew(e.target.value);

    if (e.target.value.trim().length == 0) {
      setNewPassValidation("");
      return;
    }
  };

  // useEffect(() => {
  //   setPassword({
  //     newPassword: "",
  //     confirmPassword: "",
  //   });
  // }, [isError]);

  const passwordValidateNew = (value) => {

    console.log(value, "dscksbascjblasjlc");
    const regexNum = /\d/;
    const regexUppercase = /[A-Z]/;
    const regexCharLength = /^.{8,72}$/;
    const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!regexNum.test(value)) {
      setNewPassValidation("MUST contain at least one number");
    } else if (!regexUppercase.test(value)) {
      setNewPassValidation("MUST contain at least one uppercase letter");
    } else if (!regexCharLength.test(value)) {
      setNewPassValidation("MUST contain at least 8 characters");
    } else if (!special.test(value)) {
      setNewPassValidation(
        "MUST contain at least one special character (!”#$%&'()*+,-./:;<=>?)"
      );
    } else if (value.length == 0) {
      setNewPassValidation("");
    } else {
      setNewPassValidation("");
    }
  };

  const passwordValidate = (value) => {
    console.log(value, "dscksbascjblasjlc");
    const regexNum = /\d/;
    const regexUppercase = /[A-Z]/;
    const regexCharLength = /^.{8,72}$/;
    const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (!regexNum.test(value)) {
      setConfirmValidation("MUST contain at least one number");
    } else if (!regexUppercase.test(value)) {
      setConfirmValidation("MUST contain at least one uppercase letter");
    } else if (!regexCharLength.test(value)) {
      setConfirmValidation("MUST contain at least 8 characters");
    } else if (!special.test(value)) {
      setConfirmValidation(
        "MUST contain at least one special character (!”#$%&'()*+,-./:;<=>?)"
      );
    } else {
      setConfirmValidation("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassValidation.length != 0) return;



    if (password?.newPassword.length == 0) {
      setNewPassValidation("Please enter new Password");
      return;
    }
    if (password?.confirmPassword.length == 0) {
      setConfirmValidation("Please enter confirm Password");
      return;
    }
    if (password?.newPassword !== password?.confirmPassword) {
      setConfirmValidation(<p className="text-center font-thin text-md w-full">"Confirm Password doses not match New Password"</p>);
      return;
    }

    setConfirmValidation("");
    //setNewPassValidation("")

    let data = {
      currentPasswordVal: Base64.encode(password.newPassword),
      patientCode: params,
    };
  
    dispatch(patientResetPassword(data))
      .then((result) => {
        setColor("green");
        setLoader(false);
        setConfirmValidation("The password has been reset successfully!");
        setTimeout(() => {
          message.success("The password reset successfull, Try login with your new password")
          history.push(APP_ROUTES.HOME);
        }, 1500);
      })
      .catch((res) => {
        setColor("red");
        setLoader(false);
        message.error(res.response.data.details[0]);
      });
  };

  return (
    <Layouts>
      <div>
        <div className=" flex justify-center mx-5 lg:mx-24 lg:my-24 ">
          <form
            className="mb-10 w-[600px]  bg-lightGray p-2 rounded-md"
            onSubmit={handleSubmit}
          >
            <h3 className="my-2 text-primaryColor font-medium text-center text-2xl ">
              {"Choose a new password"}
            </h3>

            <p className="text-primaryColor  text-md text-center mb-5">
              {"Create a new password that is at least 8 characters long."}
            </p>

            <div className="flex relative justify-center">
              <input
                type={newPasswordShown ? "text" : "password"}
                className="w-96 h-[50px] rounded-lg bg-White px-5 border border-gray"
                value={password.newPassword}
                placeholder="New Password ..."
                name="newPassword"
                onChange={handlePasswordChangeNewPassword}
              />
            </div>

            <div className=" justify-start">
              <span className="text-red-500   text-xs ml-8">
                {newPassValidation}
              </span>
            </div>


            <div className="text-grey-500 justify-center  text-xs my-5 flex relative">
              <ul className="list-disc flex flex-col gap-4 text-xl font-bold text-primaryColor">
                <li>{"Password Length: 8"}</li>
                <li>{"Atleast one special character"}</li>
                <li>{"Atleast one number"}</li>
                <li>{"Atleast one uppercase"}</li>
              </ul>
            </div>
            <div className="flex relative justify-center">
              <input
                type={confirmPasswordShown ? "text" : "password"}
                className="w-96 h-[50px] rounded-lg bg-White px-5 border border-gray"
                value={password.confirmPassword}
                placeholder="Confirm Password ..."
                name="confirmPassword"
                onChange={handlePasswordChange}
              />
            </div>
            <div className="flex justify-start">
              <span className="text-red-500 text-xs ml-8">
                {confirmValidation}
              </span>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                class="p-3 bg-primaryColor  text-White w-96 my-4 rounded-lg text-center text-base font-normal}"
                >
                Submit
                {showLoader && (
                  <div className="loader float-right ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-5 w-5"></div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layouts>
  );
}

export default ForgotPasswordReset;
