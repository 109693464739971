import './App.css';
import PageRouting from './Components/PageRouting';
import { Provider } from 'react-redux';
import store from '../src/Redux/store/store';
import Consent from './Components/Consent';
function App() {
  return (
    <Provider store={store}>
      <Consent />
      <PageRouting />
    </Provider>
  );
}

export default App;
