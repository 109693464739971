import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "../../Layouts";
import { Col, Row, Select } from "antd";
import hospital from "../../../Assets/Images/hospital.svg";
import { getPatientMyRequests } from "../../../Redux/Action/UserprofileActions";
import moment from "moment";
import FamilyDropdown from "./HealthRecords/FamilyDropDown";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SurgeryEnquiry from "../../Pages/Services/Sergeryenquirynew";
import { Form, Modal } from "antd";

function MyRequest() {
  const dispatch = useDispatch();
  const myrequestpatient = useSelector((state) => state.myrequestpatient);
  const { patientrequestData, isLoading } = myrequestpatient;
  const userData = useSelector((state) => state.authReducer.patientData);
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();
  const [hospitalid, sethospitalid] = useState();
  console.log(patientrequestData, "patientrequestData");
  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    dispatch(getPatientMyRequests(userData.code));
  }, []);

  const loadRequestFor = (code) => {
    dispatch(getPatientMyRequests(code));
  };
  const handleImgError = (e) => {
    e.target.src = hospital;
  };
  const handleEnquireClick = (user) => {
    setOpenModal(true);
    sethospitalid(user);
  };
  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[90%] md:w-[80%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md px-8 py-5">
            <div className=" flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-lg text-White">
                My Requests
              </div>
              <div className="hidden md:block">
                {/* <Select
                  placeholder="Get Request for "
                  className="md:h-12 md:w-96"
                ></Select> */}
                <FamilyDropdown onSelect={loadRequestFor} />
              </div>
            </div>
          </div>
          {/* <div className="block md:hidden">
            <Select
              placeholder="Get Request for "
              className="mt-3 h-12 w-full border border-gray rounded-md"
            ></Select>
          </div> */}
          <Row gutter={24} className="mt-5">
            {patientrequestData
              ?.sort((a, b) => {
                return (
                  moment(b.createdDate, "yyyy-MM-DD HH:mm:ss") -
                  moment(a.createdDate, "yyyy-MM-DD HH:mm:ss")
                );
              })
              .map((user, i) => (
                <Col xs={24} sm={24} md={12} className=" mb-5 ">
                  <div className="w-full rounded-md p-4 bg-White h-full">
                    <div className="md:flex gap-4 w-full">
                      <div className="md:w-[30%] ">
                        <img
                          src={
                            user.hospitalPhoto
                              ? `${process.env.REACT_APP_IMG_BASEURL}${user.hospitalPhoto}`
                              : hospital
                          }
                          alt={user.userName}
                          onError={handleImgError}
                          className="h-[150px] w-full rounded-lg"
                        />
                        <h1 className="font-bold text-primaryColor text-lg mt-2">
                          {user.hospitalName}
                        </h1>
                        <p className="text-secondaryColor text-sm font-medium">
                          Enquiry Form Submitted
                        </p>
                      </div>
                      <div className="mt-3 md:mt-0 grid grid-cols-1 gap-2 md:w-[70%]">
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Name</label>
                          <p className="font-medium">{user.name}</p>
                        </div>
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Request ID</label>
                          <p className="font-medium">{user.id}</p>
                        </div>
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Requested On</label>
                          <p className="font-medium">
                            {moment(user.createdDate).format("MM/DD/YYYY")}
                          </p>
                        </div>
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Notes</label>
                          <p className="font-medium">{user.notes1}</p>
                        </div>
                        {user.procedurePlannedStartDate != null && (
                          <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                            <label className="font-light">
                              Planned Date of visit
                            </label>
                            <p className="font-medium">
                              {user?.procedurePlannedDate != null
                                ? moment(user?.procedurePlannedDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                            </p>
                          </div>
                        )}
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Procedure</label>
                          {user && user.procedureName && (
                            <p className="font-medium">
                              {user.procedureName.length > 35
                                ? `${user.procedureName.substring(0, 35)}…`
                                : user.procedureName}
                            </p>
                          )}
                        </div>
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">
                            Approximate Amount for Procedure
                          </label>
                          <p className="font-medium">
                            INR {user.quotatedAmount}
                          </p>
                        </div>
                        <div className="flex justify-between items-center text-textGray text-xs md:text-sm">
                          <label className="font-light">Status</label>
                          <p className="font-medium">
                            {user.status == 0
                              ? "Rejected"
                              : user.status == 1
                              ? "Under Process"
                              : user.status == 2
                              ? "Discussing with Hospital"
                              : user.status == 3
                              ? "Discussing with Patient"
                              : user.status == 4
                              ? "Patient Denied"
                              : user.status == 5
                              ? "Hospital Denied"
                              : user.status == 6
                              ? "Procedure Confirmed"
                              : user.status == 7
                              ? "Hold"
                              : ""}
                          </p>
                        </div>
                        {user.status == 7 || user.status == 0 ? (
                          <div className="flex justify-end">
                            <button
                              onClick={() => handleEnquireClick(user)}
                              className="mt-6 p-2 bg-primaryColor w-1/2 mt-3 text-White  rounded-lg text-center text-base font-normal"
                            >
                              Resubmit
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            {patientrequestData && patientrequestData.length === 0 && (
              <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                <div className="">
                  <img src={NodataImage} alt="image" className="w-[60px]" />
                </div>
                <p className="text-center font-light item-center text-base text-textGray">
                  No request available!
                </p>
              </div>
            )}
          </Row>
        </div>
      </section>
      <Modal
        title="Surgery Enquiry Form"
        className="commonModal"
        visible={openModal}
        onOk={() => {
          setOpenModal(false);
        }}
        centered={true}
        footer={null}
        onCancel={() => {
          handleCancel();
        }}
        okText="Submit"
        cancelText="Cancel"
        width="60%"
        maskClosable={false}
      >
        <SurgeryEnquiry
          onClose={handleCancel}
          form={form}
          userdata={hospitalid}
        />
      </Modal>
    </Layouts>
  );
}

export default MyRequest;
