import React, { useState, useRef } from "react";
import DiscountIcon from "../../../Assets/Images/Cart/discountIcon.jpg";
import { useEffect } from "react";
import http from "../../../Redux/Services/http-common";
import { Skeleton } from "primereact/skeleton";
import { useHistory } from "react-router-dom";
import { applyCouponCode, getCouponList } from "./cartApi/couponapis";
import { useDispatch, useSelector } from "react-redux";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import { message, Modal } from "antd";
import Loader from "../../Shared/loader";
import { CopyOutlined } from "@ant-design/icons";
function CartPrice(props) {
  const { cartData, patient, readOnly = false, buttonText } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [verify, updateVerify] = useState("");
  const [tId, setTId] = useState("");
  const userData = useSelector((state) => state.authReducer.patientData);
  const [discount, updateDiscount] = useState(0);
  const [isShowCouponList, setShowCouponList] = useState(false);
  const [medicineCheckbox, setMedicineCheckbox] = useState(false);
  const [isCouponApply, setCouponApply] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [appliedCouponsList, setAppliedCouponsList] = useState([]);
  const [selectedCouponCode, setselectedCouponCode] = useState(null);
  const [medicineTotalAmount, setMedicineTotalAmount] = useState("0");
  const [medicineDiscountAmount, setMedicineTotalDiscountAmount] =
    useState("0");
  const [labTotalAmount, setLabTotalAmount] = useState("0");
  const [labDiscountAmount, setLabTotalDiscountAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loadingForCoupon, setLoadingForCoupon] = useState(false);
  const [couponVerify, setCouponVerify] = useState("");
  const [isMedMemberShipAreadyApplied, setIsMedMemberShipAreadyApplied] =
    useState(0);
  const [isLabMemberShipAreadyApplied, setIsLabMemberShipAreadyApplied] =
    useState(0);
  const [checkingMembership, setCheckingMembership] = useState(false);
  const [medMembershipData, setMedMembershipData] = useState({});
  const [labMembershipData, setLabMembershipData] = useState({});
  const [userFromMedicare, setUserFromMedicare] = useState(false);

  const [medmembershipError, setMedMembershipError] = useState({
    error: "",
    loading: false,
  });
  const [labmembershipError, setLabMembershipError] = useState({
    error: "",
    loading: false,
  });
  // const [cartData,setCartData] = useState({})
  const medmembershipref = useRef(null);

  const hideShow = () => {
    if (isMedMemberShipAreadyApplied > 0 || isLabMemberShipAreadyApplied > 0) {
      return;
    }
    if (isShowCouponList) {
      setShowCouponList(false);
    } else {
      setShowCouponList(true);
    }
  };

  useEffect(() => {
    checkIfCouponAppled();
    checkIfMembershipAlreadyApplied();
  }, [cartData]);

  // const handlegetCartData = () =>{
  //   getCartData(userData.code).then((res) => {
  //     if (res.data?.length) {
  //       setCartData(res.data[0]);
  //     } else {
  //     }
  //     console.log(res.data[0], "sdvudsgvuidsgviudsgvds");
  //   });
  // }

  useEffect(() => {
    setCheckingMembership(true);
    http
      .get(
        `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${patient.code}&source="B2C"`
      )
      .then((res) => {
        if (res.data.data.length && res.data.data == "Medicare") {
          setUserFromMedicare(true);
        } else if (res.data.data.length) {
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_PHAR_ORDER`
            )
            .then((res) => {
              if (cartData?.patientMedicineOrder?.length > 0) {
                if (res.data.data.length) {
                  setMedMembershipData(res.data.data[0]);
                }
              } else {
                setMedMembershipData({
                  membershipCard: 0,
                });
              }
              setCheckingMembership(false);
            })
            .catch((err) => {
              setCheckingMembership(false);
              setMedMembershipData({
                membershipCard: 0,
              });
            });
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_DIA_ORDER`
            )
            .then((res) => {
              if (cartData?.patientLabTestsOrder?.length > 0) {
                if (res.data.data.length) {
                  setLabMembershipData(res.data.data[0]);
                }
              } else {
                setLabMembershipData({
                  membershipCard: 0,
                });
              }
              setCheckingMembership(false);
            })
            .catch((err) => {
              setLabMembershipData({
                membershipCard: 0,
              });
              setCheckingMembership(false);
            });
        }

        setCheckingMembership(false);
      })
      .catch((err) => {
        setCheckingMembership(false);
        setMedMembershipData({
          membershipCard: 0,
        });
        setLabMembershipData({
          membershipCard: 0,
        });
      });
  }, [cartData, patient]);

  const checkIfMembershipAlreadyApplied = () => {
    if (!cartData) return;
    if (cartData?.patientMedicineOrder?.length) {
      setIsMedMemberShipAreadyApplied(
        cartData.patientMedicineOrder[0]?.membershipId
      );
    }

    if (cartData?.patientLabTestsOrder?.length) {
      setIsLabMemberShipAreadyApplied(
        cartData.patientLabTestsOrder[0]?.membershipId
      );
    }
  };

  const checkIfCouponAppled = () => {
    if (!cartData) return;
    let appliedCoupons = [];
    let medicineTotalAmount = 0;
    let medicineTotalDiscountedAmount = 0;
    let labTotalAmount = 0;
    let labTotalDiscountedAmount = 0;
    if (cartData.patientMedicineOrder?.length) {
      if (cartData.patientMedicineOrder[0].voucheryCode) {
        appliedCoupons.push(cartData.patientMedicineOrder[0].voucheryCode);
      }
      medicineTotalAmount = parseFloat(cartData.patientMedicineOrder[0].amount);
      medicineTotalDiscountedAmount = parseFloat(
        cartData.patientMedicineOrder[0].totalAmount
      );
    }
    if (cartData.patientLabTestsOrder?.length) {
      if (cartData.patientLabTestsOrder[0].voucherCode) {
        appliedCoupons.push(cartData.patientLabTestsOrder[0].voucherCode);
      }
      labTotalAmount = parseFloat(cartData.patientLabTestsOrder[0].totalAmount);
      labTotalDiscountedAmount = parseFloat(
        cartData.patientLabTestsOrder[0].paidAmount
      );
    }
    setMedicineTotalAmount(medicineTotalAmount);
    setMedicineTotalDiscountAmount(medicineTotalDiscountedAmount);
    setLabTotalAmount(labTotalAmount);
    setLabTotalDiscountAmount(labTotalDiscountedAmount);
    let totalDiscountAmount = parseFloat(
      medicineTotalAmount +
        labTotalAmount -
        (medicineTotalDiscountedAmount + labTotalDiscountedAmount)
    );
    setTotalDiscountAmount(totalDiscountAmount);
    setTotalAmount(medicineTotalDiscountedAmount + labTotalDiscountedAmount);
    if (appliedCoupons?.length) {
      setCouponApply(true);
    } else {
      setCouponApply(false);
    }
    setAppliedCouponsList(appliedCoupons);
  };

  useEffect(() => {
    console.log(isCouponApply, !loadingForCoupon, "sdviodshousdgvuosdo");
    if (isCouponApply && !loadingForCoupon) {
      setShowCouponList(false);
    }
  }, [isCouponApply, loadingForCoupon]);

  // const handlegetCouponList = () =>{
  //   getCouponList(userData?.code, "v_cart")
  //   .then(res =>{
  //     if(res?.data?.data?.vouchersList?.length){
  //       setCouponList(res.data.data.vouchersList)
  //     }
  //   })
  // }

  const showError = () => {
    return (
      <>
        {loadingForCoupon ? (
          <div></div>
        ) : (
          <div
            className={
              isCouponApply
                ? `text-danger mt-3 text-xs px-4`
                : `text-secondaryColor mt-3  text-xs px-4`
            }
          >
            {couponVerify === "" ? "" : couponVerify}
          </div>
        )}
      </>
    );
  };

  const errorTimer = () => {
    let myTimeout = setTimeout(() => {
      setMedMembershipError({
        loading: false,
        error: "",
      });
      setLabMembershipError({
        loading: false,
        error: "",
      });
    }, 2000);
    // clearTimeout(myTimeout)
  };

  useEffect(() => {
    if (userFromMedicare) {
      handleMedMembershipClick(null, "Medicare");
    }
  }, [userFromMedicare]);

  const handleMedMembershipClick = (e, membershipCode) => {
    if (isCouponApply) {
      setMedMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });

      errorTimer();
      return;
    }
    if (medmembershipError.loading) {
      e?.stopPropagation();
      return;
    }
    let flag = false;
    if (isMedMemberShipAreadyApplied > 0) {
      flag = false;
    } else {
      flag = true;
    }
    setMedMembershipError({
      loading: true,
    });
    e?.stopPropagation();
    if (flag) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${membershipCode}&cartId=${cartData.cartId}&serviceType=medicine&amount=${totalAmount}`
        )
        .then((res) => {
          console.log(res, "dsvdsovusdvsd");
          setMedMembershipError({
            loading: false,
            error: "",
          });
          props.getCartData();
        })
        .catch((err) => {
          console.log(err.response, "dsvdsovusdvsd");
          setMedMembershipError({
            loading: false,
            error: err.response.data.message,
          });
        });
    } else {
      let data = cartData;
      data.patientMedicineOrder[0].membershipId = 0;
      data.patientMedicineOrder[0].patientMedicineOrderDetailsList.map(
        (res) => {
          res.discountAmount = 0;
          res.totalAmount = 0;
        }
      );
      console.log(data, "sdvdsvodsuvodsuvds");
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then((res) => {
            setMedMembershipError({
              loading: false,
              error: "",
            });
          })
          .catch((err) => {
            setMedMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  const handleLabMembershipClick = (e) => {
    if (isCouponApply) {
      setLabMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });
      errorTimer();
      return;
    }
    if (medmembershipError.loading) {
      e.stopPropagation();
      return;
    }
    let flag = false;
    if (isLabMemberShipAreadyApplied > 0) {
      flag = false;
    } else {
      flag = true;
    }
    setLabMembershipError({
      loading: true,
    });
    e.stopPropagation();
    if (flag) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${labMembershipData.membershipCard}&cartId=${cartData.cartId}&serviceType=labTests`
        )
        .then((res) => {
          console.log(res, "dsvdsovusdvsd");
          setLabMembershipError({
            loading: false,
            error: "",
          });
          dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
          console.log(err.response, "dsvdsovusdvsd");
          setLabMembershipError({
            loading: false,
            error: err.response.data.message,
          });
        });
    } else {
      let data = cartData;
      data.patientLabTestsOrder[0].membershipId = 0;
      data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.map(
        (res) => {
          res.discountAmount = 0;
          res.totalAmount = 0;
        }
      );
      console.log(data, "sdvdsvodsuvodsuvds");
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then((res) => {
            setLabMembershipError({
              loading: false,
              error: "",
            });
          })
          .catch((err) => {
            setLabMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  const handleCouponSelectClick = (item, i) => {
    setselectedCouponCode(item.voucherCode);
    setAppliedCouponsList([]);
    // if (selectedCouponCode === item.voucherCode) {
    //   setselectedCouponCode(null);  // Unapply the coupon
    // } else {
    //   setselectedCouponCode(item.voucherCode);  // Apply the coupon
    // }
  };

  const [removeCouponTriggered, setRemoveCouponTriggered] = useState(false);

  const handleRemoveClick = (event, index) => {
    event.stopPropagation(); 
    handleCouponSelectClick({ voucherCode: "REMOVE" }, index);
    setRemoveCouponTriggered(true);
  };

  useEffect(() => {
    if (removeCouponTriggered) {
      handleApplyClick();
      setRemoveCouponTriggered(false);
    }
  }, [removeCouponTriggered]);
  

  const handleInputChange = (e) => {
    setselectedCouponCode(e.target.value);
  };

  const handleApplyClick = () => {
 
    if (selectedCouponCode == "") {
      setCouponVerify("Please enter valid Coupon code");

      return;
    } else if (selectedCouponCode == null) {
      setCouponVerify("Please select a  Coupon code");

      return;
    } 

    if (isMedMemberShipAreadyApplied > 0 || isLabMemberShipAreadyApplied > 0) {
      setCouponVerify("Please remove membership discount");
      return;
    }
    const validCouponCodes = ["CB_MED10", "REMOVE"];
  
  if (!validCouponCodes.includes(selectedCouponCode)) {
    setCouponVerify("This coupon code is not available");
    return;
  }

    let amountLocalStorage = JSON.parse(localStorage.getItem("couponCode"));

    console.log("amountLocalStorage", amountLocalStorage);
    console.log("amountLocalStorage", selectedCouponCode);

    if (amountLocalStorage === selectedCouponCode) {
      return;
    }
    let payloads = {
      customer_identifier: cartData?.patientId,
      total_transaction_cost: +totalAmount.toFixed(2),
      cart_id: cartData?.cartId,
      transaction_id: new Date(new Date()).getTime(),
      voucher_code: selectedCouponCode,
      total_medicine_bill: medicineTotalAmount,
      customer_type: "Registered",
      total_labtest_bill: labTotalAmount,
    };

    setLoadingForCoupon(true);
    applyCouponCode(payloads).then((res) => {
      if (res.data.errors == null) {
        setCouponVerify("Coupon Removed");
        setLoadingForCoupon(false);
        localStorage.setItem("couponCode", JSON.stringify(selectedCouponCode));
        props.getCartData();
      } else {
        setTotalDiscountAmount(0);
        setCouponVerify(res.data.errors[0].message);
        setLoadingForCoupon(false);
        props.getCartData();
      }

      console.log("is coupan response", res);
    });
    setIsModalOpen(false);
  };

  useEffect(() => {
    localStorage.setItem(
      "totalAmount",
      JSON.stringify(parseFloat(totalAmount).toFixed(2))
    );
  }, [totalAmount]);

  useEffect(() => {
    if (
      parseFloat(medicineTotalAmount).toFixed(2) -
        parseFloat(medicineDiscountAmount).toFixed(2) >
      0
    ) {
      let amount = parseFloat(medicineDiscountAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    } else {
      let amount = parseFloat(medicineTotalAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    }
  }, [medicineDiscountAmount, medicineTotalAmount]);

  console.log(isCouponApply, "dvpihwiehviewhvoehvoehvoie");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setCouponVerify("");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setCouponVerify("");
    setIsModalOpen(false);
  };
  const [text, setText] = useState("CB_MED10");
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      message.success("Copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy!");
    }
  };
  return (
    <>
      {/* {loading &&<Loader/>} */}
      {checkingMembership ? (
        <div className="w-full">
          <div className=" bg-white-600 w-full rounded-lg ">
            <p className="text-sm lg:text-base font-semibold text-gray-800 mb-3">
              Select Coupon
            </p>
            <Skeleton className="mb-2 rounded-lg" height="2rem"></Skeleton>
            <div className="bg-white rounded-lg px-7 py-3">
              <p
                className="text-sm lg:text-sm font-semibold text-gray-800 leading-24 mb-2 "
                style={{ color: "#000000" }}
              >
                Cart total
              </p>
              <Skeleton className="mb-2 rounded-lg" height="9rem"></Skeleton>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className=" w-full rounded-lg">
            {!readOnly ? (
              <div>
                <div className="flex gap-4 w-full">
                  <div className="w-full">
                    {/* <div className="flex my-3"> */}
                    <button
                      className="btn px-3 py-3 rounded cursor-pointer bg-lightBlue btn-primary w-full text-sm"
                      onClick={(e) => {
                        hideShow();
                      }}
                    >
                      <div>
                        <div className="flex w-full justify-between items-center">
                          <div className="flex">
                            <img
                              src={DiscountIcon}
                              style={{ height: "20px" }}
                              className="mr-4"
                            />
                            <span className="font-medium">Avail Coupons</span>
                          </div>
                          <button
                            onClick={showModal}
                            className="border border-primaryColor text-primaryColor text-xs font-semibold px-3 py-1.5 rounded"
                          >
                            APPLY
                          </button>
                        </div>
                        <Modal
                          title="Select Coupon"
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          footer={false}
                        >
                          <div
                            className={`w-full overflow-y-scroll overflow-x-hidden bg-white rounded-bl-lg rounded-br-lg`}
                          >
                            <div className="bg-white pb-4 sticky z-20 top-0">
                              <div className="flex flex-row space-x-2 px-4 mt-4 items-center">
                                <input
                                  placeholder="Select Coupon Code"
                                  name="code"
                                  value={selectedCouponCode || ""}
                                  onChange={handleInputChange}
                                  className=" bg-white   font-medium text-gray-500 h-12 text-left pl-2 py-2 w-full lg:w-full border border-gray-200 rounded-lg text-sm "
                                />
                                {loadingForCoupon ? (
                                  <div className="loader float-center ease-linear text-center rounded-full border-4 border-gray-200 h-6 w-6 absolute right-7" />
                                ) : (
                                  <button
                                    className="btn btn-primary mt-0 absolute rounded-lg p-2 text-black right-4 font-semibold text-sm h-12 z-30"
                                    style={{ color: "#66B889" }}
                                    onClick={handleApplyClick}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                              {showError("785785")}
                            </div>
                            <div className="flex flex-col gap-4 max-h-96 mx-4 z-10 ">
                              <div
                                className="rounded-xl p-3 cursor-pointer relative w-full"
                                style={{
                                  border: "1px solid rgba(0, 93, 141, 0.15)",
                                  background:
                                    selectedCouponCode === "CB_MED10"
                                      ? "rgba(25, 135, 84, 0.23)"
                                      : "white",
                                }}
                                onClick={() =>
                                  handleCouponSelectClick(
                                    { voucherCode: "CB_MED10" },
                                    0
                                  )
                                }
                              >
                                <div className="bg-green justify-center h-min-20 rounded-lg">
                                  <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                                    <h3 className="px-4 pb-2 pt-1 w-full">
                                      <b className="border border-1 border-[#313131] border-spacing-1 p-1">
                                        CB_MED10
                                        <CopyOutlined
                                          onClick={handleCopy}
                                          style={{
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            color: "#313131",
                                            marginLeft: "12px",
                                          }}
                                        />
                                      </b>
                                    </h3>
                                  </div>
                                  <p className="mx-4 p-1 text-grey font-normal text-sm">
                                    Flat 10% discount on all the medicines
                                  </p>

                                  <button
                                    className="absolute bottom-4 right-4 bg-danger text-White px-2 py-1 rounded mb-12 lg:mb-8"
                                    onClick={(e) => handleRemoveClick(e, 0)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>

                              {/* <div
  className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full "
  style={{
    border: "1px solid rgba(0, 93, 141, 0.15)",
    background: selectedCouponCode === 'CB_LABTEST10'
      ? "rgba(25, 135, 84, 0.23)"
      : "white",
  }}
  onClick={() => handleCouponSelectClick({ voucherCode: 'CB_LABTEST10' }, 1)}

>
  <div className="bg-green justify-center h-min-20 rounded-lg">
    <div className="flex flex-row border-b-2 border-dashed border-white w-full">
      <h3 className="px-4 pb-2 pt-1 w-full ">
        <b className="border-dashed border-2 p-1">CB_LABTEST10</b>
      </h3>
    </div>
    <p className="mx-4 p-1 text-grey font-normal text-sm">
    Flat 10% discount on all the lab tests


    </p>

    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6" style={{
      borderRight: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6" style={{
      borderLeft: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
  </div>
</div> */}

                              {/* <div
  className="border-radius: 10px rounded-xl p-3 cursor-pointer relative w-full "
  style={{
    border: "1px solid rgba(0, 93, 141, 0.15)",
    background: selectedCouponCode === 'CB_CONSULT10'
      ? "rgba(25, 135, 84, 0.23)"
      : "white",
  }}
  onClick={() => handleCouponSelectClick({ voucherCode: 'CB_CONSULT10' }, 2)}
>
  <div className="bg-green justify-center h-min-20 rounded-lg">
    <div className="flex flex-row border-b-2 border-dashed border-white w-full">
      <h3 className="px-4 pb-2 pt-1 w-full ">
        <b className="border-dashed border-2 p-1">CB_CONSULT10</b>
      </h3>
    </div>
    <p className="mx-4 p-1 text-grey font-normal text-sm">
    10% off on doctor Consultation




    </p>

    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -left-6" style={{
      borderRight: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
    <div className="bg-white w-8 h-8 rounded-full absolute top-8 -right-6" style={{
      borderLeft: "1px solid rgba(0, 93, 141, 0.15)"
    }}></div>
  </div>
</div> */}
                            </div>
                            <div className="grid grid-cols-2 items-center gap-5 mt-5">
                              {/* <button className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base">
                                Cancel
                              </button>
                              <button className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White">
                                Apply Coupon
                              </button> */}
                            </div>
                          </div>
                        </Modal>
                        {isCouponApply ? (
                          <>
                            <div className="text-secondaryColor text-xs pl-8 pt-1 mb-4">
                              Coupon code applied{" "}
                            </div>
                            <div
                              className="rounded-xl p-3 cursor-pointer relative w-full"
                              style={{
                                border: "1px solid rgba(0, 93, 141, 0.15)",
                                background:
                                  selectedCouponCode === "CB_MED10"
                                    ? "rgba(25, 135, 84, 0.23)"
                                    : "white",
                              }}
                              onClick={() =>
                                handleCouponSelectClick(
                                  { voucherCode: "CB_MED10" },
                                  0
                                )
                              }
                            >
                              <div className="bg-green justify-start h-min-20 rounded-lg">
                                <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                                  <h3 className="mx-4 my-2 pb-2 pt-1 w-full text-left">
                                    <b className="border border-1 border-[#313131] border-spacing-1 p-1">
                                      CB_MED10
                                      <CopyOutlined
                                        onClick={handleCopy}
                                        style={{
                                          fontSize: "14px",
                                          cursor: "pointer",
                                          color: "#313131",
                                          marginLeft: "12px",
                                        }}
                                      />
                                    </b>
                                  </h3>
                                </div>
                                <p className="mx-4 p-1 text-grey font-normal text-sm text-left my-2">
                                  Flat 10% discount on all the medicines
                                </p>

                                <button
                                  className="absolute right-4 bg-danger text-White px-2 py-1 rounded top-[20px]"
                                  onClick={(e) => handleRemoveClick(e, 0)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* {(isShowCouponList) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} */}
                    </button>
                  </div>
                </div>
                {cartData.patientMedicineOrder == null ? null : (
                  <>
                    {medMembershipData?.membershipCard > 0 ||
                    isMedMemberShipAreadyApplied > 0 ? (
                      <button
                        onClick={(e) =>
                          handleMedMembershipClick(
                            e,
                            medMembershipData.membershipCard
                          )
                        }
                        style={
                          isMedMemberShipAreadyApplied > 0
                            ? { backgroundColor: "#66B889", color: "#ffff" }
                            : { backgroundColor: "#ffff", color: "#1F2937" }
                        }
                        className={` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`}
                      >
                        {medmembershipError.loading && (
                          <div
                            className="absolute w-full flex justify-center"
                            style={{
                              backgroundColor: "rgb(255 255 255 / 86%)",
                            }}
                          >
                            <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                          </div>
                        )}
                        <div className="flex justify-between w-full items-center">
                          <span className="text-sm font-bold mr-4">
                            Get Membership Discount (Medicine)
                          </span>
                          <input
                            checked={
                              isMedMemberShipAreadyApplied > 0 ? true : false
                            }
                            className="cursor-pointer"
                            onChange={(e) =>
                              handleMedMembershipClick(
                                e,
                                medMembershipData.membershipCard
                              )
                            }
                            type="checkbox"
                          />
                        </div>
                        <div className="text-xs w-full font-medium text-center mt-1 text-red-600">
                          {medmembershipError.error}
                        </div>
                      </button>
                    ) : null}
                  </>
                )}

                {cartData.patientLabTestsOrder == null ? null : cartData
                    .patientLabTestsOrder[0] &&
                  cartData.patientLabTestsOrder[0]
                    .patientLabTestsOrderDetailsList.length === 0 ? null : (
                  <>
                    {labMembershipData?.membershipCard > 0 ||
                    isLabMemberShipAreadyApplied > 0 ? (
                      <button
                        onClick={handleLabMembershipClick}
                        style={
                          isLabMemberShipAreadyApplied > 0
                            ? { backgroundColor: "#66B889", color: "#ffff" }
                            : { backgroundColor: "#ffff", color: "#1F2937" }
                        }
                        className={` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`}
                      >
                        {labmembershipError.loading && (
                          <div
                            className="absolute w-full flex justify-center"
                            style={{
                              backgroundColor: "rgb(255 255 255 / 86%)",
                            }}
                          >
                            <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
                          </div>
                        )}
                        <div className="flex justify-between w-full items-center">
                          <span className="text-sm font-bold mr-4">
                            Get Membership Discount (Lab)
                          </span>
                          <input
                            checked={
                              isLabMemberShipAreadyApplied > 0 ? true : false
                            }
                            className="cursor-pointer"
                            onChange={handleLabMembershipClick}
                            type="checkbox"
                          />
                        </div>
                        <div className="text-xs w-full font-medium text-center mt-1 text-red-600">
                          {labmembershipError.error}
                        </div>
                      </button>
                    ) : null}
                  </>
                )}

                {/* {cartData.patientLabTestsOrder == null ? null :
              cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.length == 0 ? null:
              <>
              {((labMembershipData?.membershipCard > 0 || isLabMemberShipAreadyApplied > 0)) ? <button onClick={handleLabMembershipClick} style = {isLabMemberShipAreadyApplied > 0 ? {backgroundColor: "#66B889", color: "#ffff"} : {backgroundColor: "#ffff", color: "#1F2937"}} className= {` w-full mt-4 rounded-lg py-4 px-2 flex flex-col justify-between relative`} >
          {labmembershipError.loading && <div className="absolute w-full flex justify-center" style={{backgroundColor: "rgb(255 255 255 / 86%)"}} >
              <div className="loader  float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-6 w-6" />
              </div>}
              <div className=  "flex justify-between w-full items-center"  >
                <span className="text-sm font-bold mr-4" >Get Membership Discount (Lab)</span>
                <input checked = {isLabMemberShipAreadyApplied > 0 ? true : false} className = "cursor-pointer" onChange = {handleLabMembershipClick} type= "checkbox" /> 
              </div>
              <div className="text-xs w-full font-medium text-center mt-1 text-red-600" >{labmembershipError.error}</div>
              </button> : null }  
              </> } */}
                <hr className="border-t border-gray my-5" />
              </div>
            ) : null}
            <div className="w-full bg-White ">
              <p className="text-xs font-medium text-textGray leading-24 mb-4 ">
                CART BREAKDOWN
              </p>

              {medicineTotalAmount > 0 ? (
                <div>
                  <div className="flex justify-between mb-2">
                    <p className=" text-sm font-normal text-textGray">
                      Medicine Bill
                    </p>
                    <div className="flex">
                      <p></p>

                      {medicineDiscountAmount > 0 &&
                      medicineDiscountAmount != medicineTotalAmount ? (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(medicineTotalAmount).toFixed(2)}
                        </p>
                      ) : (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(medicineTotalAmount).toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {labTotalAmount > 0 ? (
                <div>
                  <div className="flex justify-between">
                    <p className=" text-sm font-normal text-textGray">
                      Lab Test Bill
                    </p>
                    <div className="flex">
                      <p></p>

                      {labDiscountAmount > 0 &&
                      labDiscountAmount != labTotalAmount ? (
                        <p className=" text-sm font-normal text-textGray">
                          ₹ {parseFloat(labTotalAmount).toFixed(2)}
                        </p>
                      ) : (
                        <p className="text-sm font-normal text-textGray">
                          ₹ {parseFloat(labTotalAmount).toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}

              {totalDiscountAmount > 0 ? (
                <div className="flex justify-between mt-2">
                  <p className=" text-sm font-normal text-textGray">
                    Total Discount
                  </p>
                  <p className=" text-sm font-normal text-secondaryColor">
                    - ₹ {parseFloat(totalDiscountAmount).toFixed(2)}
                  </p>
                </div>
              ) : null}
              <hr className="my-3 border-t border-gray" />
              <div className="flex justify-between mt-3">
                <p className="text-primaryColor font-medium">Payable Amount</p>
                <p className="text-primaryColor font-medium">
                  ₹ {parseFloat(totalAmount).toFixed(2)}
                </p>
              </div>
              <button
                className="w-full mt-4 text-sm bg-transparent font-medium  bg-primaryColor text-White py-2.5 px-4 border border-brand-secondary rounded-md"
                onClick={() => props.handleProceed("")}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CartPrice;
