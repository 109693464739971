// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Col, message, Modal, Row, Form, Input, Select } from "antd";
// import Layouts from "../../Layouts";
// import moment from "moment";
// import {
//   patientaddresslists,
//   deletePatientAddress,
//   addingpatientaddress,
//   editingpatientaddress,
// } from "../../../Redux/Action/UserprofileActions";
// const stateData = [
//   {
//     label: "Andaman and Nicobar Islands",
//     value: "Andaman and Nicobar Islands",
//   },
//   {
//     label: "Andhra Pradesh",
//     value: "AP",
//   },
//   {
//     label: "Arunachal Pradesh",
//     value: "Arunachal Pradesh",
//   },
//   {
//     label: "Assam",
//     value: "AS",
//   },
//   {
//     label: "Bihar",
//     value: "BR",
//   },
//   {
//     label: "Chandigarh",
//     value: "Chandigarh",
//   },
//   {
//     label: "Chhattisgarh",
//     value: "CT",
//   },
//   {
//     label: "Dadra and Nagar Haveli",
//     value: "Dadra and Nagar Haveli",
//   },
//   {
//     label: "Delhi",
//     value: "DL",
//   },
//   {
//     label: "Goa",
//     value: "GA",
//   },
//   {
//     label: "Gujarat",
//     value: "GJ",
//   },
//   {
//     label: "Haryana",
//     value: "HR",
//   },
//   {
//     label: "Himachal Pradesh",
//     value: "HP",
//   },
//   {
//     label: "Jammu and Kashmir",
//     value: "JK",
//   },
//   {
//     label: "Jharkhand",
//     value: "JH",
//   },
//   {
//     label: "Karnataka",
//     value: "KA",
//   },
//   {
//     label: "Kerala",
//     value: "KL",
//   },
//   {
//     label: "Madhya Pradesh",
//     value: "MP",
//   },
//   {
//     label: "Maharashtra",
//     value: "MH",
//   },
//   {
//     label: "Manipur",
//     value: "MN",
//   },
//   {
//     label: "Meghalaya",
//     value: "ML",
//   },
//   {
//     label: "Mizoram",
//     value: "MZ",
//   },
//   {
//     label: "Nagaland",
//     value: "NL",
//   },
//   {
//     label: "Odisha",
//     value: "OD",
//   },
//   {
//     label: "Puducherry",
//     value: "PY",
//   },
//   {
//     label: "Punjab",
//     value: "PB",
//   },
//   {
//     label: "Rajasthan",
//     value: "PJ",
//   },
//   {
//     label: "Tamil Nadu",
//     value: "TN",
//   },
//   {
//     label: "Telangana",
//     value: "TS",
//   },
//   {
//     label: "Tripura",
//     value: "TR",
//   },
//   {
//     label: "Uttar Pradesh",
//     value: "UP",
//   },
//   {
//     label: "Uttarakhand",
//     value: "UK",
//   },
//   {
//     label: "West Bengal",
//     value: "WB",
//   },
// ];
// function Myaddressbook(props) {
//   const dispatch = useDispatch();
//   const [Editaddress, setEditaddress] = useState();
//   const [showaddresspopup, setshowaddresspopup] = useState(false);

//   const userData = useSelector((state) => state.authReducer.patientData);
//   const addressinfo = useSelector((state) => state.patientaddresslist);
//   const { patientaddressinfoData } = addressinfo;
//   const [addaddress, setaddaddress] = useState({});
//   useEffect(() => {
//     dispatch(patientaddresslists(userData.code));
//   }, []);
//   const Editnewaddress = (event, user) => {
//     console.log("lols", user);
//     event.preventDefault();
//     setEditaddress(user);
//     setaddaddress(user);
//     setshowaddresspopup(true);
//   };
//   const Addnewaddress = (event) => {
//     event.preventDefault();
//     setEditaddress();
//     setaddaddress({
//       patientId: userData.code,
//       address1: "",
//       address2: "",
//       mobile: "",
//       type: "",
//       isDefault: "",
//       pinCode: "",
//       state: "",
//       city: "",
//       country: "",
//       status: 1,
//       createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
//       modifiedDate: "",
//       createdBy: userData.code,
//       modifiedBy: userData.code,
//     });
//     setshowaddresspopup(true);
//   };
//   const handleChange = (e) => {
//     if (e?.target.name === "isDefault") {
//       setaddaddress({ ...addaddress, [e.target.name]: e.target.checked });
//     } else {
//       setaddaddress({ ...addaddress, [e.target.name]: e.target.value });
//     }
//   };
//   const saveaddaddress = (e) => {
//     let data = addaddress;
//     data.isDefault = addaddress.isDefault ? 1 : 0;
//     data.modifiedDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
//     if (data.address1 == undefined || data.address1 == "") {
//       message.error("Please enter House Number/Street");
//     } else if (data.address2 == undefined || data.address2 == "") {
//       message.error("Please enter Address");
//     } else if (data.pinCode == undefined || data.pinCode == "") {
//       message.error("Please enter Pincode");
//     } else if (data.state == undefined || data.state == "") {
//       message.error("Please enter State");
//     } else if (data.city == undefined || data.city == "") {
//       message.error("Please Select City");
//     } else if (data.type == undefined || data.type == "") {
//       message.error("Please select address type");
//     } else {
//       if (Editaddress?.id) {
//         dispatch(editingpatientaddress(Editaddress?.id, addaddress));
//         message.success("Address Saved Successfully");
//         dispatch(patientaddresslists(userData?.code));
//       } else {
//         dispatch(addingpatientaddress(addaddress));
//         message.success("Address Saved Successfully");
//         dispatch(patientaddresslists(userData?.code));
//       }
//       setshowaddresspopup(false);
//     }
//   };
//   const onDeleteDefaultAddress = (item, index) => {
//     let payloads = {
//       id: item?.id,
//     };
//     dispatch(deletePatientAddress(payloads))
//       .then((res) => {
//         if (res) {
//           dispatch(patientaddresslists(userData?.code));
//         }
//       })
//       .catch((err) => console.log(err));
//   };
//   return (
//     <Layouts>
//       <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
//         <div className="w-[80%] mx-auto ">
//           <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
//             <div className=" flex justify-between items-center ">
//               <div className="font-medium md:text-2xl text-White">
//                 Saved Address
//               </div>
//               <div className="md:block hidden">
//                 <button
//                   onClick={(e) => Addnewaddress(e)}
//                   className="bg-primaryColor md:bg-White text-White md:text-primaryColor text-xs md:text-sm font-semibold md:py-3 px-3 md:px-7 rounded-full md:rounded"
//                 >
//                   Add a new address
//                 </button>
//               </div>
//               <div className="block md:hidden">
//                 <button
//                   onClick={(e) => Addnewaddress(e)}
//                   className="md:bg-primaryColor bg-White text-White text-primaryColor text-xs md:text-sm font-semibold py-3 px-3 md:px-7 rounded-full md:rounded"
//                 >
//                   Add a new address
//                 </button>
//               </div>
//             </div>
//           </div>
//           <Row gutter={24} className=" mt-6">
//             {patientaddressinfoData?.map((user, i) => (
//               <Col md={8}>
//                 <div className="bg-White rounded-md p-5 common-shadow h-full flex justify-between flex-col">
//                   <div className="">
//                     {/* <h5 className="text-primaryColor text-lg font-medium mb-1">{user.isDefault ? "Default Address" : "Other Address"}{" "}</h5> */}
//                     {/* <hr /> */}
//                     <div className="flex justify-between mb-2">
//                       <h5 className="text-primaryColor text-lg font-medium">
//                         {userData.firstName}{" "}
//                       </h5>
//                       <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
//                         {user.type}
//                       </div>
//                     </div>
//                     <p className="text-textGray text-sm font-medium mb-3">
//                       Address:{" "}
//                       <span className="font-light">
//                         {user.address1}, {user.address2}, {user.city},{" "}
//                         {user.state} - {user.pinCode}
//                       </span>
//                     </p>
//                   </div>
//                   <div className="flex gap-5 items-center">
//                     <div
//                       onClick={(e) => Editnewaddress(e, user)}
//                       className="text-textGray underline text-sm cursor-pointer"
//                     >
//                       Edit Address
//                     </div>
//                     <div
//                       onClick={() => onDeleteDefaultAddress(user, i)}
//                       className="text-danger underline text-sm cursor-pointer"
//                     >
//                       Delete Address
//                     </div>
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </section>
//       <Modal
//         centered={true}
//         footer={false}
//         className="commonModal"
//         title={
//           <span> {Editaddress?.id ? "Edit Address" : "Add New Address"}</span>
//         }
//         width="50%"
//         open={showaddresspopup}
//         onCancel={() => setshowaddresspopup(false)}
//       >
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5">
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               House Number/Street
//             </label>
//             <div>
//               <input
//                 autocomplete="off"
//                 id="address1"
//                 name="address1"
//                 type="text"
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//                 placeholder="Please enter your street/house"
//                 value={addaddress.address1}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               Address
//             </label>
//             <div>
//               <input
//                 autocomplete="off"
//                 id="address2"
//                 name="address2"
//                 type="text"
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//                 placeholder="Please enter your address"
//                 value={addaddress.address2}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               Pincode
//             </label>
//             <div>
//               <input
//                 autoComplete="off"
//                 id="pinCode"
//                 name="pinCode"
//                 type="number"
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//                 placeholder="Please enter your pincode"
//                 value={addaddress.pinCode}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               State
//             </label>
//             <div>
//               <select
//                 required
//                 autoComplete="off"
//                 id="state"
//                 name="state"
//                 value={addaddress.state}
//                 onChange={handleChange}
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//               >
//                 <option disabled selected hidden value="">
//                   Please select your state
//                 </option>
//                 {stateData?.map((cit, i) => (
//                   <option key={i} value={cit.value}>
//                     {" "}
//                     {cit.label}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               City
//             </label>
//             <div>
//               <input
//                 autoComplete="off"
//                 id="city"
//                 name="city"
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//                 placeholder="Enter city"
//                 value={addaddress.city}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className="">
//             <label className="text-primaryColor text-sm mb-2 inline-block">
//               Type
//             </label>
//             <div>
//               <select
//                 required
//                 className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
//                 name="type"
//                 value={addaddress.type}
//                 onChange={handleChange}
//               >
//                 <option disabled selected hidden value="">
//                   Please select your address type
//                 </option>
//                 <option
//                   className="py-1 text-xs text-gray-500"
//                   value="AddressType"
//                 >
//                   Address Type{" "}
//                 </option>
//                 <option className="py-1 text-xs" value="Home">
//                   Home{" "}
//                 </option>
//                 <option className="py-1 text-xs" value="Office">
//                   Office
//                 </option>
//                 <option className="py-1 text-xs" value="Others">
//                   Others
//                 </option>
//               </select>
//             </div>
//           </div>
//         </div>
//         <div className=" mt-5">
//           <div className="flex ">
//             <input
//               autocomplete="off"
//               id="isDefault"
//               name="isDefault"
//               type="checkbox"
//               className="w-3  mr-2 text-sm cursor-pointer"
//               checked={addaddress.isDefault}
//               onChange={handleChange}
//             />
//             <label htmlFor="mobile" className="text-textGray text-xs">
//               Is Default
//             </label>
//           </div>
//         </div>
//         <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
//           <button
//             onClick={() => setshowaddresspopup(false)}
//             className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] md:text-lg"
//           >
//             Cancel
//           </button>
//           <button
//             onClick={saveaddaddress}
//             className="bg-primaryColor w-full h-[50px] md:text-lg rounded-lg text-White"
//           >
//             Save
//           </button>
//         </div>
//       </Modal>
//     </Layouts>
//   );
// }

// export default Myaddressbook;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, message, Modal, Row, Form, Input, Select } from "antd";
import { getAllStates } from "../../../Redux/Action/hospitalpageActions";
import Layouts from "../../Layouts";
import moment from "moment";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import {
  patientaddresslists,
  deletePatientAddress,
  addingpatientaddress,
  editingpatientaddress,
} from "../../../Redux/Action/UserprofileActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
function Myaddressbook(props) {
  const dispatch = useDispatch();
  const [Editaddress, setEditaddress] = useState();
  const [showaddresspopup, setshowaddresspopup] = useState(false);

  const userData = useSelector((state) => state.authReducer.patientData);
  const addressinfo = useSelector((state) => state.patientaddresslist);
  const { patientaddressinfoData } = addressinfo;
  const [addaddress, setaddaddress] = useState({});
  const [stateData, setStateData] = useState([]);
  useEffect(() => {
    dispatch(patientaddresslists(userData.code));
  }, []);
  const Editnewaddress = (event, user) => {
    console.log("lols", user);
    event.preventDefault();
    setEditaddress(user);
    setaddaddress(user);
    setshowaddresspopup(true);
  };

  const history = useHistory();

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, [])

  useEffect(() => {
    dispatch(getAllStates())
      .then((result) => {
        setStateData(result);
      })
      .catch((error) => {});
  }, []);


  const Addnewaddress = (event) => {
    event.preventDefault();
    setEditaddress();
    setaddaddress({
      patientId: userData.code,
      address1: "",
      address2: "",
      mobile: "",
      type: "",
      isDefault: "",
      pinCode: "",
      state: "",
      city: "",
      country: "",
      status: 1,
      createdDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      modifiedDate: "",
      createdBy: userData.code,
      modifiedBy: userData.code,
    });
    setshowaddresspopup(true);
  };
  const handleChange = (e) => {
    if (e?.target.name === "isDefault") {
      setaddaddress({ ...addaddress, [e.target.name]: e.target.checked });
    } else {
      setaddaddress({ ...addaddress, [e.target.name]: e.target.value });
    }
  };
  const saveaddaddress = (e) => {
    let data = { ...addaddress };
    data.isDefault = addaddress.isDefault ? 1 : 0;
    data.modifiedDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
  
    if (data.address1 == undefined || data.address1 == "") {
      message.error("Please enter House Number/Street");
    } else if (data.address2 == undefined || data.address2 == "") {
      message.error("Please enter Address");
    } else if (data.pinCode == undefined || data.pinCode == "") {
      message.error("Please enter Pincode");
    } else if (data.state == undefined || data.state == "") {
      message.error("Please enter State");
    } else if (data.city == undefined || data.city == "") {
      message.error("Please Select City");
    } else if (data.type == undefined || data.type == "") {
      message.error("Please select address type");
    } else {
      if (Editaddress?.id) {
        dispatch(editingpatientaddress(Editaddress?.id, data))
          .then(() => {
            message.success("Address Saved Successfully");
            dispatch(patientaddresslists(userData?.code));
            setshowaddresspopup(false);
          })
          .catch((err) => console.log(err));
      } else {
        dispatch(addingpatientaddress(data))
          .then(() => {
            message.success("Address Saved Successfully");
            dispatch(patientaddresslists(userData?.code));
            setshowaddresspopup(false);
          })
          .catch((err) => console.log(err));
      }
    }
  };
  
  const onDeleteDefaultAddress = (item, index) => {
    let payloads = {
      id: item?.id,
    };
    dispatch(deletePatientAddress(payloads))
      .then((res) => {
        if (res) {
          dispatch(patientaddresslists(userData?.code));
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[80%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className=" flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Saved Address
              </div>
              <div className="md:block hidden">
                <button
                  onClick={(e) => Addnewaddress(e)}
                  className="bg-primaryColor md:bg-White text-White md:text-primaryColor text-xs md:text-sm font-semibold md:py-3 px-3 md:px-7 rounded-full md:rounded"
                >
                  Add a new address
                </button>
              </div>
              <div className="block md:hidden">
                <button
                  onClick={(e) => Addnewaddress(e)}
                  className="md:bg-primaryColor bg-White text-White text-primaryColor text-xs md:text-sm font-semibold py-3 px-3 md:px-7 rounded-full md:rounded"
                >
                  Add a new address
                </button>
              </div>
            </div>
          </div>
          <Row gutter={24} className=" mt-6">
            {patientaddressinfoData?.map((user, i) => (
              <Col md={8}>
                <div className="bg-White rounded-md p-5 common-shadow h-full flex justify-between flex-col">
                  <div className="">
                    {/* <h5 className="text-primaryColor text-lg font-medium mb-1">{user.isDefault ? "Default Address" : "Other Address"}{" "}</h5> */}
                    {/* <hr /> */}
                    <div className="flex justify-between mb-2">
                      <h5 className="text-primaryColor text-lg font-medium">
                        {userData.firstName}{" "}
                      </h5>
                      <div className="text-secondaryColor bg-lightGreen px-3 py-1.5 rounded-full text-xs cursor-pointer">
                        {user.type}
                      </div>
                    </div>
                    <p className="text-textGray text-sm font-medium mb-3">
                      Address:{" "}
                      <span className="font-light">
                        {user.address1}, {user.address2}, {user.city},{" "}
                        {user.state} - {user.pinCode}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-5 items-center">
                    <div
                      onClick={(e) => Editnewaddress(e, user)}
                      className="text-textGray underline text-sm cursor-pointer"
                    >
                      Edit Address
                    </div>
                    <div
                      onClick={() => onDeleteDefaultAddress(user, i)}
                      className="text-danger underline text-sm cursor-pointer"
                    >
                      Delete Address
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            {patientaddressinfoData && patientaddressinfoData.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No address available!
                  </p>
                </div>
              )}
          </Row>
        </div>
      </section>
      <Modal
        centered={true}
        footer={false}
        className="commonModal"
        title={
          <span> {Editaddress?.id ? "Edit Address" : "Add New Address"}</span>
        }
        width="50%"
        open={showaddresspopup}
        onCancel={() => setshowaddresspopup(false)}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5">
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              House Number/Street
            </label>
            <div>
              <input
                autocomplete="off"
                id="address1"
                name="address1"
                type="text"
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                placeholder="Please enter your street/house"
                value={addaddress.address1}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              Address
            </label>
            <div>
              <input
                autocomplete="off"
                id="address2"
                name="address2"
                type="text"
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                placeholder="Please enter your address"
                value={addaddress.address2}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              Pincode
            </label>
            <div>
              <input
                autoComplete="off"
                id="pinCode"
                name="pinCode"
                type="number"
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                placeholder="Please enter your pincode"
                value={addaddress.pinCode}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              State
            </label>
            <div>
              {/* <select
                required
                autoComplete="off"
                id="state"
                name="state"
                value={addaddress.state}
                onChange={handleChange}
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
              >
                <option disabled selected hidden value="">
                  Please select your state
                </option>
                {stateData?.map((cit, i) => (
                  <option key={i} value={cit.value}>
                    {" "}
                    {cit.label}
                  </option>
                ))}
              </select> */}
                                                   <Select
                            showSearch
                            value={addaddress.state}
                            defaultValue={addaddress.state}
                            placeholder="Please select your state"
                            optionFilterProp="children"
                            options={stateData.map((hc) => ({
                              value: hc.code,
                              value1: hc.description,
                              label: hc.description,
                              key: hc.code,
                            }))}
                            className="h-[50px] w-full text-sm"
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) =>
                              setaddaddress({ ...addaddress, ["state"]: e })
                            }
                          />
            </div>
          </div>
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              City
            </label>
            <div>
              <input
                autoComplete="off"
                id="city"
                name="city"
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                placeholder="Enter city"
                value={addaddress.city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="">
            <label className="text-primaryColor text-sm mb-2 inline-block">
              Type
            </label>
            <div>
              <select
                required
                className="h-[50px] border border-gray rounded-md w-full px-3 text-sm"
                name="type"
                value={addaddress.type}
                onChange={handleChange}
              >
                <option disabled selected hidden value="">
                  Please select your address type
                </option>
                <option
                  className="py-1 text-xs text-gray-500"
                  value="AddressType"
                >
                  Address Type{" "}
                </option>
                <option className="py-1 text-xs" value="Home">
                  Home{" "}
                </option>
                <option className="py-1 text-xs" value="Office">
                  Office
                </option>
                <option className="py-1 text-xs" value="Others">
                  Others
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className=" mt-5">
          <div className="flex ">
            <input
              autocomplete="off"
              id="isDefault"
              name="isDefault"
              type="checkbox"
              className="w-3  mr-2 text-sm cursor-pointer"
              checked={addaddress.isDefault}
              onChange={handleChange}
            />
            <label htmlFor="mobile" className="text-textGray text-xs">
              Is Default
            </label>
          </div>
        </div>
        <div className="grid grid-cols-2 items-center gap-5 mt-10 mb-5">
          <button
            onClick={() => setshowaddresspopup(false)}
            className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] md:text-lg"
          >
            Cancel
          </button>
          <button
            onClick={saveaddaddress}
            className="bg-primaryColor w-full h-[50px] md:text-lg rounded-lg text-White"
          >
            Save
          </button>
        </div>
      </Modal>
    </Layouts>
  );
}

export default Myaddressbook;

