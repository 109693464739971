import React, { useState, useEffect } from "react";
import Layouts from "../../Layouts";
import { Col, Divider, Form, Modal, Row } from "antd";
import { HiUserCircle } from "react-icons/hi2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientfamilymembers,
  onPatientfamilyMembersDelete,
} from "./../../../Redux/Action/UserprofileActions";
import moment from "moment";
import AddFamilyMember from "./AddFamilyMember";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PlusOutlined } from "@ant-design/icons";
import NodataImage from "../../../Assets/Images/Icons/nodata.svg";
import Loader from "../../Shared/loader";

function Manageprofile(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const familymemberinfo = useSelector((state) => state.familymembers);
  const { FamilymembersData, isLoading } = familymemberinfo;
  const [Editmember, setEditmember] = useState();
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [form] = Form.useForm();
  const [refresh, setIsrefresh] = useState(false);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, [])

  const handleEditmember = () => {
    setEditmember({});
  }


  const editmembers = (e, user) => {
    e.preventDefault();
    setEditmember(user);
    setshowaddmemberpopup(true);
  };

  const deleteMembers = (e, user) => {
    setLoading(true)
    e.preventDefault();
    dispatch(onPatientfamilyMembersDelete(user?.code)).then((res) => {
      setLoading(false)

      dispatch(getPatientfamilymembers(userData.code));
    });
  };

  const refreshFamilyMembers = () => {
    setIsrefresh(!refresh)
    setLoading(true)

    dispatch(getPatientfamilymembers(userData.code)).then((res) => {
      setLoading(false)

    }
    )
  };

  useEffect(() => {
    setLoading(true)

    dispatch(getPatientfamilymembers(userData.code)).then((res) => {
      setLoading(false)

    }
    )
  }, []);

  return (
    <Layouts>
            {loading &&<Loader/>}


      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[90%] md:w-[80%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md px-5 md:px-8 py-3 md:py-5">
            <div className=" flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Manage Member Profile
              </div>
              <div className="">
                <button
                  onClick={(e) => editmembers(e, "")}
                  className="bg-White text-primaryColor text-xs md:text-sm font-semibold py-3 px-3 md:px-7 rounded hidden md:block"
                >
                  Add Members
                </button>
                <button
                  onClick={(e) => editmembers(e, "")}
                  className="bg-White text-primaryColor text-sm font-bold py-1.5 px-2.5 rounded-full block md:hidden"
                >
                  <PlusOutlined />
                </button>
              </div>
            </div>
          </div>
          <div>
            <Row gutter={24}>
              {FamilymembersData.map((user, i) => (
                <Col md={8} key={i}>
                  <div className="bg-White mt-5 rounded-lg p-5">
                    <div className="flex items-center gap-3">
                      {user?.photoName ? (
                        <img
                          src={`${user.photoName}`}
                          alt={user.name}
                          className="w-14 md:w-20 h-14 md:h-20 rounded-full border"
                        />
                      ) : (
                        <HiUserCircle className="w-20 h-20 p-1 mx-2 text-[#808080]" />
                      )}
                      <div className="w-full">
                        <div className="flex justify-between w-full">
                          <div className="w-full">
                            <h2 className="text-primaryColor text-base md:text-lg font-semibold">
                              {user.name}
                            </h2>
                            <p className="text-xs md:text-sm font-light text-textGray">
                              Patient ID : <span>{user.code}</span>
                            </p>
                          </div>
                          <div className="border bg-lightGreen px-1 md:px-3 h-5 md:h-6 flex items-center rounded-full border-secondaryColor text-secondaryColor text-xs">
                            <span>{user.relation}</span>
                          </div>
                        </div>
                        <div className="flex mt-2 gap-5">
                          <p className="text-xs md:text-sm font-light text-textGray">
                            DOB:{" "}
                            <span>{moment(user.dob).format("DD/MM/YYYY")}</span>
                          </p>
                          <p className="text-xs md:text-sm font-light text-textGray">
                            Blood group: <span>{user.bloodGroup}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-5 items-center mt-3 md:mt-5">
                      <div
                        onClick={(e) => editmembers(e, user)}
                        className="text-textGray underline text-xs md:text-sm cursor-pointer"
                      >
                        Edit Profile
                      </div>
                      <div
                        onClick={(e) => deleteMembers(e, user)}
                        className="text-danger underline text-xs md:text-sm cursor-pointer"
                      >
                        Delete Profile
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
                {FamilymembersData && FamilymembersData.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No Family members available!
                  </p>
                </div>
                 )}
            </Row>
          </div>
        </div>
      </section>
      <Modal
        title={Editmember ? "Edit Family Member" : "Add a Family Member"}
        className="commonModal"
        open={showaddmemberpopup}
        onOk={() => {setshowaddmemberpopup(!showaddmemberpopup); handleEditmember()}}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
          refreshFamilyMembers();
          handleEditmember();
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <AddFamilyMember
          form={form}
          refresh={refresh}
          editmembers={Editmember}
          isEdit={Editmember? true:false}
          handleEditmember={handleEditmember}
          closePopup={() => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
            handleEditmember();
          }}
          saveMessage={(message) => {
            setshowaddmemberpopup(!showaddmemberpopup);
            refreshFamilyMembers();
          }}
        />
      </Modal>
    </Layouts>
  );
}

export default Manageprofile;

