import React, { useEffect, useState } from "react";
import Layouts from "../../Layouts";
import { Form, Input, message } from "antd";
import { getPatientDetails, patientChangepassword} from "../../../Redux/Action/UserprofileActions";
import { Base64 } from "js-base64";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { Logout } from "../../../Redux/Action/userActions";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ChangePassword = () => {
  const { isLoading, errMsg, isError, changePasswordData } = useSelector(
    (state) => state.changepassword
  );
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [digit, setDigit] = useState(false);
  const [err, setErr] = useState("");
  const [passLength, setPassLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [special, setSpecial] = useState(false);
  const [regexP] = useState(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
  );
  const [isDisable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData } = patientinfo;
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    rePassword: "",
  });
  const [confirmValidation, setConfirmValidation] = useState("");

  const [oldPasswordShown, setoldPasswordShown] = useState(false);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const [newPasswordShown, setnewPasswordShown] = useState(false);
  const { patientData, patientSession, isOpen, index } = userData;


  useEffect(()=> {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  },[])

  useEffect(() => {
    dispatch(getPatientDetails(userData.code));
  }, []);

  const logout = (e) => {
    localStorage.clear();
    let dataObj = {
      sessionId: patientSession?.id,
      userCode: patientSession?.patientCode,
    };
    dispatch(Logout(dataObj));
    history.push(APP_ROUTES.DASHBOARD);
  };

  const handleChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
    if (e.target.name === "newPassword") {
      passwordValidate(e.target.value);
    }
  };

  useEffect(() => {
  }, [patientinfoData]);

    useEffect(() => {
      setPassword({
          oldPassword: "",
          newPassword: "",
          rePassword: ""
      })

  }, [isError])

  const handleClick = () => {
    history.push('/')
  }

  const passwordValidate = (value) => {
    const regexNum = /\d/;
    const regexUppercase = /[A-Z]/;
    const regexCharLength = /^.{8,72}$/;
    const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    regexNum.test(value) ? setDigit(true) : setDigit(false);
    regexUppercase.test(value) ? setUpperCase(true) : setUpperCase(false);
    regexCharLength.test(value) ? setPassLength(true) : setPassLength(false);
    special.test(value) ? setSpecial(true) : setSpecial(false);
  };

  const savechangepassword = (e) => {
    e.preventDefault();
    let data = {
      createdBy: patientinfo.code,
      currentPasswordVal: Base64.encode(password.oldPassword),
      email: patientinfo.email,
      mobile: patientinfo.mobile,
      modifiedBy: userData.code,
      passWordVal: Base64.encode(password.rePassword),
      patientCode: userData.code,
      status: 1,
    };
    dispatch(patientChangepassword(data))
      .then((result) => {
        setConfirmValidation("The password has been changed successfully!");
        // <Alert onClose={() => {}}>This is a success alert — check it out!</Alert>
        setTimeout(() => {
          message.success('The password has been changed successfully');
          history.push({ pathname: APP_ROUTES.HOME, state: { background: "/", login: true } });
          logout();
        }, 1500);
      })
      .catch((res) => {
        message.success(res.response.data.details[0]);
      });

    console.log("Message", changePasswordData);

    // if(isDisable) {
    //     toast('Password Saved Successfully');
    //     setErr("");
    //     // setErr('feedback saved successfully');
    // }else{
    //     setErr(' saved successfully');
    // }
    // to dispatch newly created object to createlocation action
  };

  useEffect(() => {
    if (
      password.rePassword === password.newPassword &&
      regexP.test(password.newPassword) &&
      password.oldPassword
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [password]);

  useEffect(() => {
    if (userData?.id) {
    } else {
      history.push({
        pathname: APP_ROUTES.LOGIN,
        state: { background: location, login: true },
      });
    }
  }, []);

  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="w-[80%] mx-auto">
          <div className="w-full bg-primaryColor rounded-md px-8 py-7">
            <div className="font-medium md:text-2xl text-White">
              Change Password
            </div>
          </div>
          <div className="w-full rounded-md p-5 bg-White mt-5">
            <div className="md:w-[50%] mx-auto">
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                className="CommonForm grid grid-cols-1 gap-3 md:gap-5"
              >
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      Current Password <span className="text-danger">*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="oldPassword"
                    value={password.oldPassword}
                    onChange={handleChange}
                    placeholder="Please enter current password"
                  />
                </Form.Item>
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      New Password <span className="text-danger">*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="newPassword"
                    value={password.newPassword}
                    onChange={handleChange}
                    placeholder="Please enter new password"
                  />
                </Form.Item>
                <div className="flex flex-wrap gap-4">
                    <span disabled className={`${passLength ? "text-secondaryColor " : "text-danger"} cursor-not-allowed bg-gray  w-34 text-xs px-2 py-1  rounded-sm mr-2`}>8 characters</span>
                    <span disabled className={`${special ? "text-secondaryColor " : "text-danger"} cursor-not-allowed bg-gray w-34 text-xs px-2 py-1  rounded-sm mr-2`}>1 special character</span>
                    <span disabled className={`${upperCase ? "text-secondaryColor " : "text-danger "} cursor-not-allowed bg-gray w-34 text-xs px-2 py-1  rounded-sm mr-2`}>1 uppercase</span>
                    <span disabled className={`${digit ? "text-secondaryColor " : "text-danger"} cursor-not-allowed bg-gray text-xs  px-2 w-34 py-1 p rounded-sm mr-2`}>1 numeric</span>
                </div>
                <Form.Item
                  className="mb-0"
                  label={
                    <div className="text-primaryColor">
                      Confirm New Password{" "}
                      <span className="text-danger">*</span>
                    </div>
                  }
                >
                  <Input.Password
                    name="rePassword"
                    value={password.rePassword}
                    onChange={handleChange}
                    placeholder="Please enter confirm new password"
                  />
                </Form.Item>
                {password?.rePassword && password?.newPassword !== password?.rePassword && <span className="text-danger  text-xs">Confirm New Password doses not match New Password.</span>}
                <span className="text-danger text-xs">{errMsg}</span>
                <span className="text-secondaryColor text-xs">{confirmValidation}</span>
              </Form>

              <div className="grid grid-cols-2 items-center gap-5 mb-5">
                <button onClick={handleClick} className="bg-White text-primaryColor border-primaryColor border rounded-lg w-full h-[50px] text-base">
                  Cancel
                </button>
                <button
                  onClick={savechangepassword}
                  disabled={isDisable}
                  className="bg-primaryColor w-full h-[50px] text-base rounded-lg text-White"
                >
                  Save Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default ChangePassword;
