import React, { useEffect, useState } from "react";
import Layouts from "../../../Layouts";
import { Col, Row, Select } from "antd";
import Image from "../../../../Assets/Images/Rectangle4812104.svg";
import VideoImage from "../../../../Assets/Images/videoIcon.svg";
import calender from "../../../../Assets/Images/calender.png";
import clock from "../../../../Assets/Images/AppointmentClock.svg";

import {useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import doctorImage from "../../../../Assets/Images/doctorImage.svg";
import patientService from "../../../../Redux/Services/patientService";
import { deleteAppointment, removeUpdateSuccess } from "../../../../Redux/Action/doctorAction";
import { APP_ROUTES } from "../../../PageRouting/AppRoutes";
import CancelAppointmentPopup from "../CancelAppointmentPopup";
import { Modal } from "antd";
import AppointmentDetailspopup from "../Appointmentdetailspopup";
import { getLocalTime } from "../../../Helper/LocalTimeFormat";
import RescheduleModal from "../RescheduleModal";
import EprescriptionsView from "../../Services/EprescriptionsView";
import FamilyDropdown from "./FamilyDropDown";
import NodataImage from"../../../../Assets/Images/Icons/nodata.svg";

function MyAppointments() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showCancelappointmentpopup, setshowCancelappointmentpopup] =
    useState(false);

  const [appointmentdetails, setappointmentdetails] = useState();
  const [isHide, setHide] = useState(true);
  const [isErrorMsg, setErrorMsg] = useState("");
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [appointmentlistData, setAppointmentlistData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const location = useLocation();
  const doctorAppointment = useSelector((state) => state.doctorAppointment);
  const { success, isError } = doctorAppointment;
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const [selectedUserCode, setSelectedUserCode] = useState(patientCode);
  const [prescriptionView, setPrescriptionView] = useState(false);
  const patientdetails = useSelector((state) => state.particularpatientdetails);
  const [userID, setUserId] = useState(null);
  const userData = useSelector((state) => state.authReducer.patientData);



  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push('/');
    }
  }, [])

  useEffect(() => {
    if (success && showCancelDialog) {
      setShowCancelDialog(false);
    }
    if (isError && showCancelDialog) {
      setError("Something went wrong");
    }
    getPatientAppointment(selectedUserCode);
  }, [success, isError]);

  const redirectTo = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const redirectToVideo = (event, location, data) => {
    event.preventDefault();
    history.push({ pathname: location, state: data });
  };

  const handlePrescription = (userId) => {
    setPrescriptionView(true);
    setUserId(userId);
  };

  const handleOk = () => {
    setPrescriptionView(false);
  };

  const handleCancel = () => {
    setPrescriptionView(false);
    setUserId(null);
  };

  const viewprofile = (e, doct) => {
    e.preventDefault();
    setShowModal(true);
    setappointmentdetails(doct);
  };

  const openModal = (val) => {
    setappointmentdetails(val);
    setHide(false);
  };

  const getPatientAppointment = (code) => {
    let payload = {
      patientId: code,
      photoRequired: "Y",
    };
    setLoading(true);
    patientService
      .getpatientappointmentlistFilter(payload)
      .then((res) => {
        if (res.data) {
          setAppointmentlistData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getFamilyMemberAppointment = (patientCode) => {
    setSelectedUserCode(patientCode);
    let payload = {
      patientId: patientCode,
      photoRequired: "Y",
    };
    setLoading(true);

    patientService
      .getpatientappointmentlistFilter(payload)
      .then((res) => {
        if (res.data) {
          setAppointmentlistData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onRescheduleModelClose = () => {
    setHide(true);
    // getPatientAppointment();
    dispatch(removeUpdateSuccess());
  };

  const confirmCancelDialog = (e, val) => {
    e.preventDefault();
    setappointmentdetails(val);
    setShowCancelDialog(true);
  };

  const confirmCancelAppointment = (reason) => {
    if (reason == "") {
      setErrorMsg("Please select reason");
      return;
    }
    dispatch(deleteAppointment(appointmentdetails?.id, reason))
      .then((res) => {
        setShowCancelDialog(false);
      })
      .catch((err) => setErrorMsg(err?.response?.data?.details[0]));
  };

  const isTimeExceed = (data) => {
    let appointmentDate = new Date(
      `${data.whenAppointment} " " ${data.fromTime}`
    );
    let isAllowedToCancel = Math.floor(
      (Date.now() - appointmentDate.getTime()) / 1000 / 60
    );
    if (isAllowedToCancel >= -4) {
      return false;
    } else {
      return true;
    }
  };

  const gotoHistory = (e) => {
    e.preventDefault();

    if (location.state == undefined) {
      history.push(APP_ROUTES.MYPROFILE);
    } else {
      history.push("/");
    }
  };

  const handlePayClick = (user) => {
    let name = user.userName.replaceAll(" ", "-");
    history.push({
      pathname: `/doctors/${name}-${user.userId}`,
      search: `?when=${user.whenAppointment}&from=${user.fromTime}&paid=no&id=${user.patientId}&reason=${user.consultationsReason}&rel=${user.relation}&key=${user.id}`,
    });
  };

  const handleImgError = (e) => {
    e.target.src = doctorImage;
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray md:min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md md:px-8 px-6 md:py-5 py-3">
            <div className="md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                My Appointments
              </div>
              <div className="hidden md:block">
                {/* <Select
                  placeholder="Get Appointment for "
                  className="md:h-12 h-10 md:w-96  w-[200px]"
                  onSelect={getFamilyMemberAppointment}
                > */}
                <FamilyDropdown title={"Get Appointments for"} onSelect={getFamilyMemberAppointment} />
                {/* </Select> */}
              </div>
            </div>
          </div>
          {/* <div className="md:hidden block">
            <Select
              placeholder="Get Appointment for "
              className="h-12 w-full border border-gray rounded-md"
            >
            </Select>
          </div> */}
          <Row gutter={24} className="">
            {appointmentlistData.map((user, i) => (
              <Col xs={24} sm={24} md={8}>
                <div className="w-full rounded-md p-4 my-2 bg-White">
                  <div className="flex gap-5">
                    <div className="w-[76px] bg-lightBlue rounded-full">
                      <img
                        src={user.patientPhotoName}
                        alt="img"
                        className="w-[60px] h-[60px] object-fit rounded-full"
                      />
                    </div>
                    <div className="flex justify-between w-full items-baseline gap-5">
                      <div className="">
                        <h6 className="text-primaryColor text-lg font-medium mb-1">
                          {user.userName}
                        </h6>
                        <p className="text-primaryColor text-xs flex gap-2">
                          Video Consultation
                        </p>
                      </div>
                      <button
                        onClick={(e) => viewprofile(e, user)}
                        className="text-secondaryColor hover:underline"
                      >
                        View
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full gap-3 items-center font-medium mt-3">
                    {user.status == 8 ? (
                      ""
                    ) : user.status == 2 ? (
                      ""
                    ) : user.status == 3 ? (
                      ""
                    ) : user.status == 4 ? (
                      ""
                    ) : user.status == 5 ? (
                      ""
                    ) : user.status == 1 ? (
                      <div>
                        {user.consultationsType === "V" && (
                          <div
                            onClick={(e) =>
                              redirectToVideo(e, APP_ROUTES.VIDEO_CALL, user)
                            }
                            className="text-[#005D8D] underline text-xs cursor-pointer"
                          >
                            Join Call
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {user.status === 15 && (
                    <div className="text-[#005D8D] underline text-xs cursor-pointer">
                      
                        <button
                          onClick={(e) => handlePayClick(user)}
                          className="text-brand-secondary hover:text-brand-secondary"
                        >
                          Pay
                        </button>
                    </div>
                      )}

                    <div className="text-[#005D8D] underline text-xs cursor-pointer">
                      <button
                        key={user.id}
                        onClick={() =>
                          // history.push({
                          //   pathname: APP_ROUTES.POST_CONSULTATION,
                          //   search: `?id=${user.id}`,
                          // })
                          handlePrescription(user.id)
                        }
                        disabled={user.status !== 2}
                        className="disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                      >
                        Prescription
                      </button>
                    </div>
                    <div className="text-textGray underline text-xs cursor-pointer">
                      {user?.isConfirm !== "1" && (
                        <button
                          onClick={() => openModal(user)}
                          disabled={user.status !== 1}
                          className="w-auto text-left md:w-1/3 md:mr-2 disabled:opacity-50 text-brand-secondary hover:text-brand-secondary"
                        >
                          Reschedule
                        </button>
                      )}
                    </div>
                    <div className="text-danger underline text-xs cursor-pointer">
                      {user?.isConfirm !== "1" && (
                        <button
                          onClick={(e) => confirmCancelDialog(e, user)}
                          className="w-auto text-left md:w-1/3 md:mr-2 text-brand-secondary disabled:opacity-50 hover:text-brand-secondary"
                          disabled={user.status !== 1 || !isTimeExceed(user)}
                          style={{ width: "max-content" }}
                        >
                          Cancel Appt.
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-3 bg-lightBlue rounded-full w-fit h-7 items-center px-4 mt-3">
                    <div className="flex items-center gap-3 text-primaryColor text-xs">
                      <img src={calender} alt="img" className="h-3" />
                      {getLocalTime(user.whenAppointment)} {user.fromTime}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
              {appointmentlistData && appointmentlistData.length === 0 && (
                <div class=" w-full flex gap-5 flex-col justify-center min-h-[50vh] items-center text-center ">
                  <div className="">
                    <img src={NodataImage} alt="image" className="w-[60px]"/>
                  </div>
                  <p className="text-center font-light item-center text-base text-textGray">
                    No appointments available!
                  </p>
                </div>
                 )}
          </Row>
         
        </div>
      </section>

      <Modal
        visible={prescriptionView}
        //  open={prescriptionView}
        width={900}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <EprescriptionsView userID={userID} />
      </Modal>

      {!isHide && (
        <RescheduleModal
          data={appointmentdetails}
          onClose={() => onRescheduleModelClose()}
        />
      )}

      {showCancelDialog ? (
        <CancelAppointmentPopup
          isErrorMsg={isErrorMsg}
          onCancel={confirmCancelAppointment}
          isLoading={isLoading}
          close={() => {
            setShowCancelDialog(false);
            setErrorMsg("");
          }}
        />
      ) : null}

      {showModal ? (
        <AppointmentDetailspopup
          appointmentdetails={appointmentdetails}
          closePopup={() => {
            setShowModal(!showModal);
            onRescheduleModelClose();
          }}
          isVisible={isTimeExceed(appointmentdetails)}
        ></AppointmentDetailspopup>
      ) : null}
    </Layouts>
  );
}

export default MyAppointments;
